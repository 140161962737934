<template>
  <div>
    <h2 class="mb-4">Activity</h2>
    <summary-section :summaries="summaries" />

    <div class="purchase-history">
      <h2 class="mb-4">Purchase History</h2>
      <month-filter @startEndDate="onMonthFilter" />
      <div class="d-flex justify-content-end text-right pb-2">
        <form-search v-model="searchQuery" @onSearch="onSearch" />
      </div>
      <data-table
        :dataArray="filteredList"
        :columns="activitiesColumns"
        :actionLink="actionLink"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import SummarySection from "@/components/summary/Summary";
import DataTable from "@/components/datatable/datatable.vue";
import FormSearch from "@/components/form/search";
import monthFilter from "@/components/shared/MonthFilter";

export default {
  name: "VenueActivity",
  components: {
    SummarySection,
    DataTable,
    FormSearch,
    monthFilter,
  },
  data() {
    return {
      summaries: [],
      summariesTemplate: [
        {
          type: "latest",
          title: "Last Watched",
          subtitle: "daily",
          description: "-",
          class: "success", // info, primary
          faIcon: "fa-clock-o",
          descriptionTitle: "",
        },
        {
          type: "total",
          title: "Total Upload",
          subtitle: null,
          description: "-",
          class: "primary", // info, primary
          faIcon: "fa-cloud-upload",
          descriptionTitle: "Total upload",
        },
        {
          type: "totalTicket",
          title: "Total Tickets Sold",
          subtitle: null,
          description: "-",
          class: "info", // info, primary
          faIcon: "fa-ticket",
          descriptionTitle: "Total tickets sold",
        },
        {
          type: "totalRevenue",
          title: "Total Revenue",
          subtitle: "Total",
          description: "-",
          class: "success", // info, primary
          faIcon: "fa-money",
          descriptionTitle: "Total revenue",
        },
      ],
      purchaseHistory: [],
      activitiesColumns: [
        { field: "id", header: "Order Id", sortable: true },
        {
          field: "datePurchased",
          header: "Date of Purchase",
          sortable: true,
        },
        { field: "user", header: "User" },
        {
          field: "productName",
          header: "Video Name",
          sortable: true,
        },
        {
          field: "price",
          header: "Price",
          sortable: true,
        },
        {
          field: "receipt",
          header: "Action",
          icon: "fa fa-eye mr-2",
          label: "View",
        },
      ],
      actionLink: "EXTERNAL_LINK",
      searchQuery: null,
      filteredList: [],
      fromDate: null,
      toDate: null,
    };
  },
  mounted() {},
  methods: {
    setSummaries(orderHistory) {
      this.summaries = this.summariesTemplate;

      if (orderHistory.length) {
        const latestOrder = orderHistory.sort((a, b) =>
          b.createdDateTime.localeCompare(a.createdDateTime)
        )[0];

        // total videos uploaded
        let videoCount = {};
        let totalRev = 0;
        orderHistory.forEach(function(item) {
          videoCount[item.productId]
            ? videoCount[item.productId]++
            : (videoCount[item.productId] = 1);

          totalRev = totalRev + item.meta.payment.totalPrice;
        });
        const totalVideosUpload = Object.keys(videoCount).length;

        // TODO: think about the result paginated from API
        const totalTickets = orderHistory.length;

        // total revenue
        const totalRevenues = `${getSymbolFromCurrency("GBP")} ${totalRev}`;

        const now = moment().utc();
        const latestOrderDate = moment(latestOrder.createdDateTime);

        this.summaries[0].description = `${moment
          .duration(now.diff(latestOrderDate, "hours"), "hours")
          .humanize()} ago`;
        this.summaries[1].description = totalVideosUpload;
        this.summaries[2].description = totalTickets;
        this.summaries[3].description = totalRevenues;
      }
    },
    getVenuePurchaseHistories() {
      const venueId = this.$route.params.id;

      const params = {
        id: venueId,
        from: this.fromDate,
        to: this.toDate,
      };
      this.$store
        .dispatch("venues/GET_PURCHASE_HISTORY", params)
        .then((res) => {
          const orderData = res.data.data.sort(
            (a, b) =>
              new Date(b.createdDateTime).getTime() -
              new Date(a.createdDateTime).getTime()
          );

          const orderHistory = orderData.map((order) => {
            return {
              id: order.id.slice(-8),
              datePurchased: moment
                .utc(order.createdDateTime)
                .format("DD/MMM/YYYY"),
              user: `${order.meta.customer.firstName} ${order.meta.customer.lastName}`,
              productName: order.meta.product.name,
              price: `${
                order.meta.payment.currency
                  ? getSymbolFromCurrency(order.meta.payment.currency)
                  : getSymbolFromCurrency("GBP")
              } ${order.meta.payment.totalPrice}`,
              receipt: order.meta.payment.receipt,
            };
          });
          this.purchaseHistory = orderHistory;
          this.filteredList = this.purchaseHistory;
          this.setSummaries(orderData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearch: function() {
      console.log(this.searchQuery);
      const originalList = Array.from(this.purchaseHistory);

      if (!this.searchQuery) this.filteredList = originalList;

      let search = originalList.filter((x) => {
        const productName = x.productName.toLowerCase();
        const user = x.user.toLowerCase();
        if (
          productName.includes(this.searchQuery) ||
          user.includes(this.searchQuery)
        )
          return x;
      });
      this.filteredList = Array.from(search);
    },
    onMonthFilter: function(e) {
      this.fromDate = e.startDate;
      this.toDate = e.lastDate;
      this.getVenuePurchaseHistories();
    },
  },
};
</script>
