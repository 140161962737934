<template>
  <div class="align-items-center d-flex justify-content-end">
    <input
      :value="value"
      class="form-control"
      :placeholder="placeholderText"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="sendSearch"
    />
  </div>
</template>

<script>
export default {
  props: ["value", "placeholder"],
  data() {
    return {
      placeholderText: "",
    };
  },
  mounted() {
    this.placeholderText = this.placeholder || "Search...";
  },
  methods: {
    sendSearch() {
      this.$emit("onSearch");
    },
  },
};
</script>
