<template>
  <div>
    <small-loader class="d-block mx-auto" v-if="loading" />
    <div v-else>
      <ValidationObserver ref="venueForm">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label"
            >Venue Name<span class="astx">*</span></label
          >
          <div class="col-sm-8">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                name="venue_name"
                type="text"
                class="form-control"
                v-model="detail.name"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label"
            >Email Address<span class="astx">*</span></label
          >
          <div class="col-sm-8">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <input
                name="email"
                type="text"
                class="form-control"
                v-model="detail.email"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label"
            >Contact Number<span class="astx">*</span></label
          >
          <div class="col-sm-8">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                name="contact"
                type="text"
                class="form-control"
                v-model="detail.contact"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Street Address</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.address" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Address line 2</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.address2" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">City</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.city" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">PostCode</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.postCode" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">State</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.state" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Country</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.country" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Latitude</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.latitude" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Longitude</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              v-model="detail.longitude"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8 offset-sm-2 mb-4">
            <i class="fa fa-info-circle mr-1" /> Get latitude and longitude
            <a
              href="https://www.latlong.net/convert-address-to-lat-long.html"
              target="_blank"
              ><u>here</u></a
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Website</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="detail.website" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Description</label>
          <div class="col-sm-8">
            <textarea
              rows="4"
              class="form-control"
              v-model="detail.description"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Hero images</label>
          <div class="col-sm-8">
            <image-upload
              :url="base_url + '/venue/manage/upload?path=hero'"
              :data="detail.hero_images"
              :name="'hero'"
              :maxFileSize="1500000"
              :multiple="true"
              @onUpload="onUploadHero"
              @onDelete="deleteImage"
            ></image-upload>
          </div>
        </div>
        <!-- no need separate thubmnail file in venue ATM <div class="form-group row">
          <label class="col-sm-2 col-form-label">Thumbnail images</label>
          <div class="col-sm-8">
            <image-upload
              :url="base_url + '/venue/upload?path=thumbnail'"
              :data="detail.thumbnail_images"
              :name="'thumbnail'"
              :maxFileSize="1000000"
              @onUpload="onUploadThumbnail"
              @onDelete="deleteImage"
            ></image-upload>
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Thumbnail image</label>
          <div class="col-sm-8">
            <image-upload
              :url="base_url + '/venue/manage/upload?path=thumbnail_small'"
              :data="detail.thumbnail_small_images"
              :name="'thumbnail_small'"
              :maxFileSize="500000"
              :multiple="false"
              @onUpload="onUploadThumbnailSmall"
              @onDelete="deleteImage"
            ></image-upload>
          </div>
        </div>
        <!--<div class="form-group row">
          <label class="col-sm-2 col-form-label">Media Files</label>
          <div class="col-sm-8">
            <div class="image-container">
              <div v-for="(item, idx) of detail.artworks" :key="idx">
                <img :src="item.value" />
                <span
                  @click="deleteImage(item.key, item.value)"
                  class="delete label label-plain btn btn-default btn-circle"
                  ><i class="fa fa-times"></i
                ></span>
              </div>
            </div>
          </div>
        </div>-->

        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Similar Venues (max 3)</label>
          <div class="col-sm-8">
            <select
              class="form-control"
              multiple=""
              v-model="detail.similarVenues"
              @change="checkMax(detail.similarVenues)"
            >
              <option
                v-for="venue in similarVenuesMasterList"
                :value="venue.id"
                :key="venue.id"
                :selected="venue.selected"
              >
                {{ venue.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Meta Title</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              v-model="detail.metaTitle"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Meta Description</label>
          <div class="col-sm-8">
            <textarea
              rows="4"
              class="form-control"
              maxlength="250"
              v-model="detail.metaDescription"
            />
          </div>
        </div>
      </ValidationObserver>
    </div>

    <span class="font-weight-bold">* required fields</span>
    <div class="hr-line-dashed"></div>
    <div class="form-group row">
      <div class="col-sm-4 col-sm-offset-2">
        <button class="btn btn-primary btn-lg mr-2" @click="saveOrUpdate">
          <i class="fa fa-arrow-down"></i>&nbsp;Save changes
        </button>
        <button
          class="btn btn-default btn-white btn-lg"
          @click="
            $router.push({
              name: 'venues',
            })
          "
        >
          <i class="fa fa-times"></i>&nbsp;Cancel
        </button>
      </div>
      <div class="col-sm-8 text-right" v-if="id">
        <ConfirmationModal
          title="Delete Venue?"
          message="You can't undo this action"
          :actionAccept="confirmAccept"
          :actionReject="confirmReject"
          btnAcceptStyle="background-color:red;"
          ref="deleteModal"
        />

        <ConfirmationModal
          title="Cannot delete venue"
          :message="cannotDeleteMessage"
          :actionAccept="confirmReject"
          btnAcceptTitle="OK"
          ref="cannotDelete"
        />

        <button class="btn btn-danger btn-lg" @click="deletePrompt">
          <i class="fa fa-trash-o"></i>&nbsp;Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SmallLoader from "@/components/SmallLoader";
import ImageUpload from "@/components/form/ImageUpload";

export default {
  name: "VenueForm",
  data() {
    return {
      detail: {
        id: "",
        name: "",
        email: "",
        contact: "",
        address: "",
        address2: "",
        city: "",
        postCode: "",
        state: "",
        country: "",
        description: "",
        hero_images: [],
        thumbnail_images: [],
        thumbnail_small_images: [],
        artworks: [],
        latitude: "",
        longitude: "",
        similarVenues: [],
        website: "",
        metaTitle: "",
        metaDescription: "",
      },
      id: null,
      base_url: process.env.VUE_APP_API_BASE_URL,
      similarVenuesMasterList: [],
      cannotDeleteMessage: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch("venues/GET_VENUE", this.id).then((res) => {
        this.mapData(res);
        this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
      });
    }

    this.populateSimilarVenuesMasterList();
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.venueData,
      venuesList: (state) => state.venues.listOfVenues,
      loading: (state) => state.general.dataLoading,
    }),
    isVenueCannotDelete() {
      return this.venue.isShowingNow;
    },
    readOnly: function() {
      return this.id != null;
    },
    breadcrumbs: function() {
      return [
        { title: "Home", route: "/" },
        { title: "Venues", route: "/venues" },
        { title: this.detail.name, route: null },
      ];
    },
  },
  methods: {
    mapData: function(data) {
      this.detail.id = data.id;
      this.detail.name = data.name;
      this.detail.email = data.email;
      this.detail.contact = data.contact;
      this.detail.address = data.address;
      this.detail.address2 = data.secondAddress;
      this.detail.city = data.city;
      this.detail.postCode = data.postCode;
      this.detail.state = data.state;
      this.detail.country = data.country;
      this.detail.description = data.description;
      this.detail.longitude = data.longitude;
      this.detail.latitude = data.latitude;
      this.detail.website = data.website;
      this.detail.metaTitle = data.metaTitle;
      this.detail.metaDescription = data.metaDescription;

      if (data.mediaFiles && data.mediaFiles.length > 0) {
        this.detail.artworks = data.mediaFiles;
        this.detail.hero_images = data.mediaFiles.filter(
          (o) => o.key === "hero"
        );
        this.detail.thumbnail_images = data.mediaFiles.filter(
          (o) => o.key === "thumbnail"
        );
        this.detail.thumbnail_small_images = data.mediaFiles.filter(
          (o) => o.key === "thumbnail_small"
        );
      }
      this.detail.similarVenues = data.similarVenues || [];
    },
    populateSimilarVenuesMasterList() {
      // console.log(this.venuesList);
      this.$store.dispatch("venues/GET_VENUES", "id,name").then((res) => {
        this.similarVenuesMasterList = res
          .filter((item) => item.id !== this.id)
          .map((venue) => {
            venue.selected = this.detail.similarVenues.includes(venue.id);
            return venue;
          });
      });
    },
    checkMax(val) {
      console.log(val);
      if (val.length > 3) {
        console.log(" you can only select three");
        val.splice(3);
      }
    },
    onUploadHero: function(event) {
      var result = JSON.parse(event.xhr.response);
      // console.log("s3 resp from server", result);
      for (let location of result) {
        let model = {
          key: "hero",
          value: location.Location,
        };
        this.detail.artworks.push(model);
        this.detail.hero_images = this.detail.artworks.filter(
          (f) => f.key == "hero"
        );
      }
      // auto update if id is exist
      if (this.id) this.update();
    },
    onUploadThumbnail: function(event) {
      var result = JSON.parse(event.xhr.response);
      for (let location of result) {
        let model = {
          key: "thumbnail",
          value: location.Location,
        };
        this.detail.artworks.push(model);
        this.detail.thumbnail_images = this.detail.artworks.filter(
          (f) => f.key == "thumbnail"
        );
      }
      // auto update if id is exist
      if (this.id) this.update();
    },
    onUploadThumbnailSmall: function(event) {
      var result = JSON.parse(event.xhr.response);
      for (let location of result) {
        let model = {
          key: "thumbnail_small",
          value: location.Location,
        };
        // splice
        const idx = this.detail.artworks.findIndex(
          (id) => id.key == "thumbnail_small"
        );
        if (idx >= 0) this.detail.artworks.splice(idx, 1);

        // push new model
        this.detail.artworks.push(model);

        this.detail.thumbnail_small_images = this.detail.artworks.filter(
          (f) => f.key == "thumbnail_small"
        );
      }
      // auto update if id is exist
      if (this.id) this.update();
    },
    deleteImage: function(key, value) {
      const idx = this.detail.artworks.findIndex(
        (i) => i.value == value && i.key == key
      );

      this.detail.artworks.splice(idx, 1);

      if (key == "hero") {
        const idx = this.detail.hero_images.findIndex((i) => i.value == value);
        if (idx >= 0) this.detail.hero_images.splice(idx, 1);
      } else if (key == "thumbnail") {
        const idx = this.detail.thumbnail_images.findIndex(
          (i) => i.value == value
        );
        if (idx >= 0) this.detail.thumbnail_images.splice(idx, 1);
      } else if (key == "thumbnail_small") {
        const idx = this.detail.thumbnail_small_images.findIndex(
          (i) => i.value == value
        );
        if (idx >= 0) this.detail.thumbnail_small_images.splice(idx, 1);
      }
    },
    saveOrUpdate: async function() {
      const success = await this.$refs.venueForm.validate();
      if (success) {
        if (this.detail.id) this.update();
        else this.save();
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Warning",
          detail: "* required fields",
          life: 3000,
        });
      }
    },
    save: function() {
      this.$store.dispatch("venues/SAVE_VENUE", this.detail).then((resp) => {
        if (resp.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Venue save successfully.",
            life: 3000,
          });
          this.$router.push("/venues");
        }
      });
    },
    update: function() {
      this.$store.dispatch("venues/UPDATE_VENUE", this.detail).then((resp) => {
        if (resp.status == 200) {
          this.mapData(resp.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Venue update successfully.",
            life: 3000,
          });
        }
      });
    },
    deleteVenue: function() {
      this.$store
        .dispatch("venues/DELETE_VENUE", this.id)
        .then(() => {
          const idx = this.venuesList.findIndex((v) => v.id === this.id);
          this.venuesList.splice(idx, 1);

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Venue delete successfully.",
            life: 3000,
          });
          this.$router.push("/venues");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePrompt: function() {
      if (this.isVenueCannotDelete) {
        this.cannotDeleteMessage =
          "Cannot delete venue. The venue still has one or more active shows";

        this.$refs.cannotDelete.show();
      } else {
        this.$refs.deleteModal.show();
      }
    },
    confirmAccept: function() {
      this.deleteVenue();
    },
    confirmReject: function() {
      // To do nothing
    },
  },
  components: {
    SmallLoader,
    ImageUpload,
  },
};
</script>

<style lang="scss">
.image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  img {
    max-height: 187px;
  }
}
.delete {
  position: relative;
  top: 10px;
  right: 40px;
}
</style>
