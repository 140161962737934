<template>
  <div class="faq-page">
    <small-loader class="d-block mx-auto" v-if="loading" />
    <div v-else>
      <page-heading>FAQ</page-heading>
      <div hidden class="text-right pb-2">
        <button type="submit" class="btn btn-primary" @click="saveClick()">
          Save
        </button>
      </div>
      <div class="tabs-container">
        <div class="tabs-left">
          <ul class="nav nav-tabs">
            <li v-for="(content, idx) in newContent" :key="idx">
              <a
                :class="tabsIndex === idx ? 'nav-link active' : 'nav-link'"
                data-toggle="tab"
                @click="tabChanges(idx)"
              >
                {{ content.title }}
              </a>
            </li>
            <li>
              <a
                class="nav-link primary"
                data-toggle="tab"
                @click="addSection()"
              >
                + add section
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(content, index) in newContent"
              :key="index"
              :class="tabsIndex === index ? 'tab-pane active' : 'tab-pane'"
            >
              <div class="panel-body">
                <ConfirmationModal
                  title="Delete FAQ?"
                  message="You can't undo this action"
                  :actionAccept="confirmAccept"
                  :actionReject="confirmReject"
                  btnAcceptStyle="background-color:red;"
                  ref="deleteModal"
                />

                <div class="text-right pb-2">
                  <button
                    type="submit"
                    class="btn btn-primary mr-2"
                    @click="onSaveClick()"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-warning"
                    @click="deletePrompt()"
                  >
                    <i class="fa fa-trash"></i> Remove
                  </button>
                </div>
                <ValidationObserver ref="contentForm">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Category*</label>
                    <div class="col-sm-10">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          class="form-control"
                          :value="content.category"
                          @input="onCategoryChange"
                        />
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Title*</label>
                    <div class="col-sm-10">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          class="form-control"
                          :value="content.title"
                          @input="onTitleChange"
                        />
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Sort Order*</label>
                    <div class="col-sm-10">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <input
                          type="number"
                          class="form-control"
                          :value="content.sortOrder"
                          @input="onSortOrderChange"
                          min="0"
                          max="999"
                        />
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Content*</label>
                    <div class="col-sm-10">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <textarea
                          class="form-control"
                          @input="onContentChange"
                          placeholder="add Content"
                          :value="content.content"
                          rows="21"
                        ></textarea>
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import PageHeading from "@/components/pageComponents/PageHeading";
import SmallLoader from "@/components/SmallLoader";
// import WysiwygEditor from "@/components/wysiwyg/WysiwygEditor";

export default {
  data() {
    return {
      editor: null,
      tabsIndex: 0,
      newContent: [],
    };
  },
  created() {
    this.$store.dispatch("content/GET_CONTENT_FAQ").then((result) => {
      result.map((data) => {
        this.newContent.push({
          id: data.id,
          title: data.title,
          category: data.category,
          content: data.content,
          sortOrder: data.sortOrder,
        });
      });
    });
  },
  mounted() {},
  methods: {
    onContentChange(evt) {
      this.newContent[this.tabsIndex].content = evt.target.value;
      // console.log("onContentChange", evt.target.value);
    },
    onTitleChange(evt) {
      this.newContent[this.tabsIndex].title = evt.target.value;
      // console.log("onTitleChange", evt.target.value);
    },
    onCategoryChange(evt) {
      this.newContent[this.tabsIndex].category = evt.target.value;
    },
    onSortOrderChange(evt) {
      this.newContent[this.tabsIndex].sortOrder = evt.target.value;
    },
    tabChanges(index) {
      console.log("index", index);
      Vue.set(this, "tabsIndex", index);
      this.tabsIndex = index;
    },
    saveClick() {
      console.log("save data", this.content);
    },
    addSection() {
      this.newContent.push({
        content: "",
        title: "new Section",
        category: "",
        sortOrder: 0,
      });
    },
    removeClick() {
      const faq = this.newContent[this.tabsIndex];
      if (!faq.id) this.newContent.splice(this.tabsIndex, 1);
      else {
        this.$store.dispatch("content/DELETE_CONTENT_FAQ", faq).then((r) => {
          if (r.status === 200) {
            // splice content
            this.newContent.splice(this.tabsIndex, 1);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Faq deleted successfully.",
              life: 3000,
            });
          }
        });
      }
    },
    async onSaveClick() {
      const success = await this.$refs.contentForm[this.tabsIndex].validate();
      console.log("save data", this.newContent[this.tabsIndex]);
      if (success) {
        const faq = this.newContent[this.tabsIndex];
        if (faq.id) await this.updateFaq(faq);
        else await this.saveNewFaq(faq);
      }
    },
    async saveNewFaq(faq) {
      this.$store
        .dispatch("content/SAVE_CONTENT_FAQ", faq)
        .then((r) => {
          if (r.status === 200) {
            console.log("data", r);
            this.newContent[this.tabsIndex].id = r.data;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Faq saved successfully.",
              life: 3000,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateFaq(faq) {
      this.$store
        .dispatch("content/UPDATE_CONTENT_FAQ", faq)
        .then((r) => {
          if (r.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Faq updated successfully.",
              life: 3000,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deletePrompt() {
      this.$refs.deleteModal[this.tabsIndex].show();
    },
    confirmAccept() {
      const faq = this.newContent[this.tabsIndex];
      if (!faq.id) this.newContent.splice(this.tabsIndex, 1);
      else {
        this.$store.dispatch("content/DELETE_CONTENT_FAQ", faq).then((r) => {
          if (r.status === 200) {
            // splice content
            this.newContent.splice(this.tabsIndex, 1);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Faq deleted successfully.",
              life: 3000,
            });
          }
        });
      }
    },
    confirmReject() {},
  },
  beforeDestroy() {},
  computed: {
    ...mapState({
      contentfaq: (state) =>
        state.content.contentfaq.map((data) => {
          return {
            id: data.id,
            title: data.title,
            category: data.category,
            content: data.content,
            sortOrder: data.sortOrder,
          };
        }),
      loading: (state) => state.general.dataLoading,
    }),
    ...mapGetters(["content/getContentFaq"]),
  },
  watch: {
    newContent(newVal) {
      console.log("contentfaq", newVal);
    },
  },
  components: {
    PageHeading,
    SmallLoader,
    // WysiwygEditor,
  },
};
</script>
