const general = {
  namespaced: true,
  state: {
    bodyClass: "",
    miniNavbar: false,
    mobileNavbar: false,
    menuActive: [],
    dataLoading: false,
    breadcrumbs: []
  },
  mutations: {
    setMininavbar(state, payload) {
      state.miniNavbar = payload;
    },
    setMobilenavbar(state, payload) {
      state.miniNavbar = payload;
    },
    setBodyClass(state, payload) {
      state.bodyClass = payload;
    },
    toggleMenuActive(state, menuId) {
      if (!menuId) return;

      // remove
      if (state.menuActive.includes(menuId)) {
        const index = state.menuActive.indexOf(menuId);
        if (index > -1) {
          state.menuActive.splice(index, 1);
        }
      } else {
        state.menuActive.push(menuId);
      }
    },
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    },
    setDataLoading(state, payload) {
      state.dataLoading = payload;
    },
  },
  actions: {
    SET_MININAVBAR({ commit, dispatch }, payload) {
      commit("setMininavbar", payload);
      const classname = payload ? 'mini-navbar' : ''
      dispatch('SET_BODYCLASS', classname);
    },
    SET_MOBILENAVBAR({ commit, dispatch }, payload) {
      commit("setMobilenavbar", payload);
      const classname = payload ? 'mobile-nav-active' : ''
      dispatch('SET_BODYCLASS', classname);
    },
    SET_BODYCLASS({ commit }, payload) {
      commit("setBodyClass", payload);
    },
    SET_BREADCRUMBS({ commit }, payload) {
      commit('setBreadcrumbs', payload)
    },
    TOGGLE_MENU({ commit }, payload) {
      commit("toggleMenuActive", payload);
    },
    SET_DATALOADING({ commit }, payload) {
      commit("setDataLoading", payload);
    },
  },
  getters: {
    isMenuActive: state => menuId => {
      return state.menuActive.includes(menuId);
    },
    breadcrumbs: state => state.breadcrumbs,
  },
};

export default general;
