<template>
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      v-for="(breadcrumb, idx) in breadcrumbs"
      :key="idx"
    >
      <router-link :to="breadcrumb.route" v-if="breadcrumb.route">
        {{ breadcrumb.title }}
      </router-link>
      <strong v-else> {{ breadcrumb.title }}</strong>
    </li>
  </ol>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      breadcrumbs: (state) => state.general.breadcrumbs,
    }),
  },
  mounted() {
    console.log(this.breadcrumbs);
  },
};
</script>
