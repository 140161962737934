<template>
  <div class="listing-filter-container">
    <div class="row">
      <div class="col-3">
        <div class="input-group row">
          <label class="col-lg-2 col-form-label">Filter</label>
          <div class="filter-input-container">
            <input
              type="text"
              ref="filterinput"
              class="form-control"
              :value="filterKeyword"
              @input="$emit('update:filterKeyword', $event.target.value)"
            />
            <button
              type="button"
              class="btn btn-lg btn-clear"
              v-if="filterKeyword"
              @click="clearFilter"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="text-right pb-2">
          <!-- BUTTON ADD NEW SLOT -->
          <slot />
        </div>
      </div>
      <div class="col-3">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            @keyup.enter="doSearch"
            :value="searchKeyword"
            @input="$emit('update:searchKeyword', $event.target.value)"
          />
          <span class="input-group-append">
            <button type="submit" class="btn btn-primary" @click="doSearch">
              search
            </button>
          </span>

          <button
            type="button"
            class="btn"
            @click="resetSearch"
            v-if="showSearchResult"
          >
            Show All
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterKeyword: {
      type: String,
      default: "",
    },
    searchKeyword: {
      type: String,
      default: "",
    },
    showSearchResult: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearFilter() {
      this.$emit("clearFilter");
      this.$refs.filterinput.focus();
    },
    doSearch() {
      this.$emit("doSearch");
    },
    resetSearch() {
      this.$emit("resetSearch");
    },
  },
};
</script>
