<template>
  <MultiSelect
    v-model="selectedValues"
    :options="options"
    :optionLabel="label"
    :placeholder="placeHolder"
    @change="onSelectData"
    display="chip"
  ></MultiSelect>
</template>
<script>
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    MultiSelect,
  },
  props: ["options", "selectedOptions", "label", "placeHolder", "maxSelection"],
  data() {
    return {
      selectedValues: [],
    };
  },
  mounted() {
    this.mapSelectedOptions();
  },
  methods: {
    mapSelectedOptions() {
      if (this.options && this.selectedOptions) {
        this.options.forEach(v => {
          if (this.selectedOptions.some(f => f.id === v.id))
            this.selectedValues.push(v);
        });
        console.log("mapped data", this.selectedValues);
      }
    },
    onSelectData(event) {
      console.log("event selected", event);
      if (this.maxSelection && this.selectedValues.length > this.maxSelection) {
        this.selectedValues.splice(4);
        this.$emit("maxSelectionReached", true);
      }

      this.$emit("dataSelected", this.selectedValues);
    },
  },
};
</script>
