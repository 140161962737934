<template>
  <div class="summary-container row">
    <div class="col-md-3" v-for="(summary, idx) in summaries" :key="idx">
      <ibox-section :data="summary"></ibox-section>
    </div>
  </div>
</template>

<script>
import IboxSection from "@/components/ibox/ibox.vue";

export default {
  props: {
    summaries: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    IboxSection,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  margin-bottom: 20px;
}
</style>