<template>
  <div>
    <FileUpload
      name="file"
      :url="url"
      :multiple="multiple"
      :mode="mode"
      :maxFileSize="maxFileSize"
      accept="image/*"
      @upload="onUpload"
      @before-send="prepRequest($event)"
      @error="onFailedUpload"
    >
      <template #empty>
        <p>Drag and drop files to here to upload</p>
      </template>
    </FileUpload>
    <div class="image-container" v-if="data">
      <div v-for="(item, idx) of data" :key="idx" class="d-inline-flex mb-2">
        <img :src="item.value" />
        <span
          @click="onDeleteImage(item.value)"
          class="delete label label-plain btn btn-default btn-circle"
          ><i class="fa fa-times"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import AuthTokens from "@/models/AuthTokens";

export default {
  props: ["url", "data", "name", "maxFileSize", "multiple"],
  methods: {
    onUpload: function(event) {
      this.$emit("onUpload", event);
    },
    onDeleteImage: function(value) {
      this.$emit("onDelete", this.name, value);
    },
    prepRequest: function(event) {
      const tokenData = localStorage.getItem("tokens") || "{}";
      const { idToken = null } = new AuthTokens(JSON.parse(tokenData));

      event.xhr.setRequestHeader("Authorization", idToken);
    },
    onFailedUpload: function() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to upload image",
        life: 3000,
      });
    },
  },
  components: {
    FileUpload,
  },
  computed: {
    mode: function() {
      return this.multiple ? "advanced" : "basic";
    },
  },
};
</script>
