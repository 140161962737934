import Vue from "vue";
import Vuex from "vuex";
import counter from "./modules/counter";

import general from "./modules/general";

import auth from "./modules/auth";
import users from "@/pages/users/store/index.js";
import venues from "@/pages/venues/store/index.js";
import videos from "@/pages/videos/store/index.js";
import featured from "@/pages/featured/store/index.js";
import content from "@/pages/content/store/index.js";

Vue.use(Vuex);

const store = {
  modules: {
    general,
    auth,
    counter,
    users,
    venues,
    videos,
    featured,
    content,
  },
};

export default new Vuex.Store(store);
