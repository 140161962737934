import Vue from 'vue';
import VueRouter from "vue-router";
import store from "@/store";

// Global layout
import DefaultLayout from "@/layouts/Default";
import NoSidebarLayout from "@/layouts/NoSidebar";

// Landing pages
import Home from "@/pages/dashboard/Index.vue";

// Auth Components
import SignIn from "@/pages/auth/SignInPage.vue";
import SignOut from "@/pages/auth/SignOutPage.vue";
import ForgotPassword from "@/pages/auth/ForgotPasswordPage.vue";
import ConfirmPassword from "@/pages/auth/ConfirmPasswordPage.vue";


// Video Components
import VideosPage from "@/pages/videos/Index.vue";
import VideoDetail from "@/pages/videos/VideoDetail.vue";
import VenuesPage from "@/pages/venues/Index.vue";
import UsersPage from "@/pages/users/Index.vue";
import UserDetail from "@/pages/users/UserDetail.vue";
import VenueDetail from "@/pages/venues/details.vue";

import SupportPage from "@/pages/support/Index.vue";

import FeaturedShowsPage from "@/pages/featured/Index.vue";
import ContentFaqPage from "@/pages/content/Faq.vue";

Vue.use(VueRouter);

const isUserSignedIn = async () => await store.getters["auth/isSignedIn"];

const routes = [
  {
    path: "",
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Home,
        meta: {
          cssClass: "dashboard",
        },
      },
      {
        path: "/videos",
        name: "videos",
        component: VideosPage,
        meta: {
          cssClass: "videos-landing",
        },
      },
      {
        path: "/videos/new",
        name: "addVideo",
        component: VideoDetail,
        props: true,
        meta: {
          cssClass: "video-detail",
        },
      },
      {
        path: "/videos/:id",
        name: "videoDetail",
        component: VideoDetail,
        props: true,
        meta: {
          cssClass: "video-detail",
        },
      },
      {
        path: "/venues",
        name: "venues",
        component: VenuesPage,
        meta: {
          cssClass: "vendors-landing",
        },
      },
      {
        path: "/venues/:id",
        name: "venueDetail",
        component: VenueDetail,
        meta: {
          cssClass: "vendors-landing"
        }
      },
      {
        path: "/venues/add",
        name: "venueAdd",
        component: VenueDetail,
        meta: {
          cssClass: "vendors-landing"
        }
      },
      {
        path: "/users",
        name: "users",
        component: UsersPage,
        meta: {
          cssClass: "users-landing",
        },
      },
      {
        path: "/users/:id",
        name: "userDetail",
        component: UserDetail,
        props: true,
        meta: {
          cssClass: "users-detail",
        },
      },
      {
        path: "/users/add",
        name: "addUser",
        component: UserDetail,
        props: true,
        meta: {
          cssClass: "users-detail",
        },
      },
      {
        path: "/featured",
        name: "featured",
        component: FeaturedShowsPage ,
        meta: {
          cssClass: "featured-shows-page",
        },
      },
      {
        path: "/support",
        component: SupportPage,
        meta: {
          cssClass: "support-page",
        },
      },
      {
        path: "/signout",
        name: "signout",
        component: SignOut,
      },
      {
        path: "/faq",
        name: "faq",
        component: ContentFaqPage ,
        meta: {
          cssClass: "faq-page",
        },
      },
    ],
  },
  {
    path: "",
    name: "NoSidebar",
    component: NoSidebarLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/signin",
        name: "signin",
        component: SignIn,
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/confirm-password",
        name: "confirm-password",
        component: ConfirmPassword,
      }
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/signin', '/forgot-password', '/confirm-password'];
  const authRequired = !publicPages.includes(to.path);

  const userIsSignedIn = await isUserSignedIn();

  if (authRequired && !userIsSignedIn) {
    return next('/signin');
  } else {
    next();
  }
});

export default router;
