<template>
  <div class="container">
    <bar-chart :chartData="chartdata" :options="options" />
  </div>
</template>

<script>
import BarChart from "@/components/chart/BarChart.vue";

export default {
  name: "DashboardContainer",
  data: () => ({
    loaded: false,
    chartdata: {
      labels: [
        "Little Match Girl",
        "Plutot La Vie",
        "Aladdin",
        "Anna Karenina",
        "Satria Bergitar",
      ],
      datasets: [
        {
          label: "Views",
          backgroundColor: [
            "#3e95cd",
            "#8e5ea2",
            "#3cba9f",
            "#e8c3b9",
            "#c45850",
          ],
          data: [13, 26, 44, 28, 32],
        },
      ],
    },
    options: {
      legend: { display: false },
      title: {
        display: true,
        text: "Most Watched Shows 2020",
      },
    },
  }),
  components: {
    BarChart,
  },
};
</script>
<style lang="scss" scoped>
.chart {
  max-height: 260px;
}
</style>

