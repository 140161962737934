<template>
  <div
    class="middle-box text-center loginscreen animated fadeInDown d-flex vh-100 justify-content-center align-items-center pt-0 login-page"
  >
    <b-container fluid="md">
      <div class="mb-4">
        <router-link to="/">
          <img src="~@/assets/images/logo.png" alt="Box Office VR" />
        </router-link>
      </div>
      <h3 class="mb-4">Sign In</h3>
      <p style="width: 300px"></p>

      <!-- form -->
      <validation-observer ref="loginFrm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="signIn">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="userName"
              vid="userName"
              rules="required|email"
            >
              <b-form-input
                id="userName"
                v-model="loginForm.userName"
                :state="errors.length > 0 ? false : null"
                name="userName"
                placeholder="username / email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="loginForm.password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="invalid || isLoading"
          >
            <span v-if="isLoading">Loading..</span>
            <span v-else>Sign in</span>
          </b-button>
        </b-form>
      </validation-observer>

      <p class="text-center mt-2">
        <b-link :to="{ name: 'forgot-password' }"> Forgot password? </b-link>
      </p>

      <p class="m-t">
        <small>Box Office VR © {{ year }}</small>
      </p>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BLink,
  BContainer,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import { required, email, password } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  name: "SignIn",
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BLink,
    BContainer,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      password,
      loginForm: {
        userName: "",
        password: "",
        rememberMe: false,
      },
    };
  },
  async mounted() {
    await this.$store.getters["auth/isSignedIn"] ? this.$router.push("/") : this.$router.push("/signin");
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.general.dataLoading,
    }),
    year: () => new Date().getFullYear(),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async signIn() {
      try {
        const success = await this.$refs.loginFrm.validate();
        if (success) {
          await this.$store.dispatch("auth/signIn", this.loginForm);
          this.$router.push({ name: "dashboard" });
          window.location.reload();
        }
      } catch (err) {
        this.showError(err);
      }
    },
    showError(err) {
      this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
    },
  }
}
</script>
