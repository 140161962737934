import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import { ModalPlugin, ToastPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin,TooltipPlugin } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import "@/libs/toastification";
import ConfirmationModal from "@/components/modal/Confirm";

// Composition API
Vue.use(VueCompositionAPI);

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("ConfirmationModal", ConfirmationModal);

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 0,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
    dateFormat: "dd/mm/yy",
    weekHeader: "Wk",
  },
});
Vue.use(ToastService);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(TooltipPlugin);

require("@core/assets/fonts/feather/iconfont.css");
require("@core/scss/core.scss");
require("./assets/sass/app.scss");

// Primevue
import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";

//vee validate
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

// rules for vee validate
extend("email", email);
extend("required", {
  ...required,
  message: "This field is required",
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  console.error(err, vm, info);

  if (err.message === "401") {
    window.location.href = "/signin"; 
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
