<template>
  <div class="tabs-container">
    <ul class="nav nav-tabs" role="tablist">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(tab, index)"
      >
        <a
          class="nav-link"
          data-toggle="tab"
          :class="determineCssClasses(tab, index)"
        >
          {{ tab.title }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: null,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    selectTab: function(tab, idx) {
      this.tabIndex = idx;
      this.$emit("onClickTab", idx);
    },
    determineCssClasses: function(tab, idx) {
      var classes = idx == this.tabIndex ? "active" : "";
      return classes;
    },
  },
};
</script>
