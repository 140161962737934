<template>
  <div class="row">
    <div class="col-12">
      <h2 class="mb-5">Activity</h2>

      <div class="purchase-history">
        <h2 class="mb-4">Purchase History</h2>
        <month-filter @startEndDate="onMonthFilter" />
        <div class="d-flex justify-content-end text-right pb-2">
          <form-search
            :placeholder="'Search by title'"
            v-model="searchQuery"
            @onSearch="onSearch"
          />
        </div>
        <div class="row">
          <div class="col-12">
            <small-loader class="d-block mx-auto" v-if="dataLoading" />
            <data-table
              v-if="!dataLoading"
              :dataArray="filteredList"
              :columns="columns"
              :actionLink="actionLink"
            >
            </data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import SmallLoader from "@/components/SmallLoader";
import DataTable from "@/components/datatable/datatable";
import getSymbolFromCurrency from "currency-symbol-map";
import monthFilter from "@/components/shared/MonthFilter";
import FormSearch from "@/components/form/search";

export default {
  props: ["id"],
  data() {
    return {
      purchaseHistory: [],
      columns: [],
      actionLink: "EXTERNAL_LINK",
      startEndDate: null,
      filteredList: [],
      searchQuery: null,
      fromDate: null,
      toDate: null,
    };
  },
  created() {
    // this.$store.dispatch("users/GET_USERS");
    // this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);

    console.log("this id", this.id);
    this.columns = [
      { field: "id", header: "Order Id", sortable: true },
      { field: "title", header: "Content Title" },
      {
        field: "datePurchased",
        header: "Date of Purchase",
        sortable: true,
      },
      {
        field: "purchaseExpiry",
        header: "Purchase Expiry",
        sortable: true,
      },
      {
        field: "watchExpiry",
        header: "Watch Expiry",
        sortable: true,
      },
      {
        field: "price",
        header: "Price",
        sortable: true,
      },
      {
        field: "receipt",
        header: "Action",
        icon: "fa fa-eye mr-2",
        label: "View",
      },
    ];
  },
  computed: {
    ...mapState({
      dataLoading: (state) => state.general.dataLoading,
    }),
    ...mapGetters(["getUsers"]),
  },
  methods: {
    getUserPurchaseHistory: function() {
      const params = {
        id: this.id,
        from: this.fromDate,
        to: this.toDate,
      };

      console.log("activity Detail params", params);
      this.$store.dispatch("general/SET_DATALOADING", true, { root: true });
      this.$store
        .dispatch("users/GET_PURCHASE_HISTORY", params)
        .then((res) => {
          const orderData = res.data.data.sort(
            (a, b) =>
              new Date(b.createdDateTime).getTime() -
              new Date(a.createdDateTime).getTime()
          );

          const orderHistory = [];

          orderData.forEach((order) => {
            orderHistory.push({
              id: order.id.slice(-8),
              title: order.meta.product.name,
              datePurchased: moment(order.createdDateTime)
                .format("DD/MMM/YYYY"),
              purchaseExpiry: moment(order.validUntilDateTime)
                .format("DD/MMM/YYYY h:mm a"),
              watchExpiry: moment(order.watchingExpiry || order.validUntilDateTime)
                .format("DD/MMM/YYYY h:mm a"),
              price: `${
                order.meta.payment.currency
                  ? getSymbolFromCurrency(order.meta.payment.currency)
                  : getSymbolFromCurrency("GBP")
              } ${order.meta.payment.totalPrice}`,
              receipt: order.meta.payment.receipt,
            });
          });

          this.purchaseHistory = orderHistory;
          this.filteredList = this.purchaseHistory;
          this.$store.dispatch("general/SET_DATALOADING", false, {
            root: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("general/SET_DATALOADING", false, {
            root: true,
          });
        });
    },
    onMonthFilter: function(e) {
      this.fromDate = e.startDate;
      this.toDate = e.lastDate;
      this.getUserPurchaseHistory();
    },
    onSearch: function() {
      console.log(this.searchQuery);
      const originalList = Array.from(this.purchaseHistory);

      if (!this.searchQuery) this.filteredList = originalList;
      let search = originalList.filter((x) => {
        const productName = x.title.toLowerCase();
        if (productName.includes(this.searchQuery)) return productName;
      });
      this.filteredList = Array.from(search);
    },
  },
  components: {
    SmallLoader,
    DataTable,
    monthFilter,
    FormSearch,
  },
};
</script>
