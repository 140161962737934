<template>
  <Calendar
    :value="value"
    :dateFormat="dateFormat"
    @date-select="dateSelected"
    :yearNavigator="true"
    :yearRange="yearRange"
    :monthNavigator="true"
    :maxDate="today"
  />
</template>
<script>
import Calendar from "primevue/calendar";
import moment from "moment";
export default {
  props: {
    value: null,
    dateFormat: {
      type: String,
      default: "dd/mm/yy",
    },
  },
  data() {
    let currentDate = moment().toDate();
    return {
      today: currentDate,
      yearRange: "1900:" + currentDate.getFullYear().toString(),
    };
  },
  components: {
    Calendar,
  },
  methods: {
    dateSelected(date) {
      this.$emit("calendarSelected", date);
    },
  },
};
</script>
