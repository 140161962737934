import algoliasearch from "algoliasearch/lite";

export default class AlgoliaSearchApiProxy {
  constructor({
    algoliaAppId = process.env.VUE_APP_ALGOLIA_APP_ID,
    algoliaAppSearchApiKey = process.env.VUE_APP_ALGOLIA_SEARCH_KEY,
    algoliaMainIndexName = process.env.VUE_APP_ALGOLIA_MAIN_INDEX_NAME,
  } = {}) {
    if (!algoliaAppId) throw new Error("Algolia App ID is required");
    if (!algoliaAppSearchApiKey) throw new Error("Algolia App Api Key For Searching is required");
    if (!algoliaMainIndexName) throw new Error("Algolia Main Index Name is required");

    this.client = algoliasearch(algoliaAppId, algoliaAppSearchApiKey);
    this.index = this.client.initIndex(algoliaMainIndexName);
  }

  async query(keyword,
      attributesToRetrieve = ["objectID", "title", "vendor", "totalViews"],
      filterBy = ["meta:ShowDetails"]) {
    return new Promise((resolve, reject) => {
      this.index.search(keyword, {
        attributesToRetrieve,
        facetFilters: filterBy,
      }).then(({ hits })=> {
        console.log(hits);
        
        resolve(hits.map(x => { x.id = x.objectID; return x; }));
      }).catch(err => {
        reject(err);
      });
    });
  }

}