<template>
  <div>
    <h2 class="mb-5">Video Activity</h2>
    <!--
        <b-row class="mb-5">
          <b-col md="12">
            <statistics :data="statisticsItems" />
          </b-col>
        </b-row>
        -->

    <b-row>
      <b-col md="12">
        <h3>Purchase History</h3>
      </b-col>
      <b-col md="6"><month-filter @startEndDate="onMonthFilter"/></b-col>
      <b-col md="6" class="d-flex justify-content-end text-right pb-2">
        <form-search v-model="searchQuery" @onSearch="onSearch" />
      </b-col>
      <b-col md="12">
        <data-table
          :dataArray="filteredList"
          :columns="activitiesColumns"
          :actionLink="actionLink"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import Statistics from "./Statistics.vue";
import DataTable from "@/components/datatable/datatable.vue";
import FormSearch from "@/components/form/search";
import MonthFilter from "@/components/shared/MonthFilter";

export default {
  components: {
    BRow,
    BCol,
    // Statistics,
    DataTable,
    FormSearch,
    MonthFilter,
  },
  directives: {
    Ripple,
  },
  props: {
    video: null,
  },
  data() {
    return {
      actionLink: "EXTERNAL_LINK",
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "890",
          subtitle: "Tickets Sold",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "PlayCircleIcon",
          color: "light-info",
          title: "1201",
          subtitle: "Play",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "$15000",
          subtitle: "Revenue",
          customClass: "",
        },
      ],
      activitiesColumns: [
        { field: "id", header: "Order ID", sortable: true },
        { field: "createdDateTime", header: "Purchase Date", sortable: true },
      ],
      purchaseHistory: [],
      filteredList: [],
      searchQuery: null,
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    //this.getVideoPurchaseHistories();
  },
  computed: {
    ...mapState({
      dataLoading: state => state.general.dataLoading,
    }),
    ...mapGetters(["getUsers"]),
  },
  methods: {
    onMonthFilter: function(date) {
      this.searchQuery = null;
      this.startDate = date.startDate;
      this.endDate = date.lastDate;
      this.getVideoPurchaseHistories();
    },
    onSearch: function() {
      console.log(this.searchQuery);
      const originalList = Array.from(this.purchaseHistory);

      if (!this.searchQuery) this.filteredList = originalList;
      let search = originalList.filter(x => {
        //x.user === this.searchQuery
        const user = x.user.toLowerCase();
        if (user.includes(this.searchQuery)) return user;
      });
      this.filteredList = Array.from(search);
    },
    getVideoPurchaseHistories: function() {
      const videoId = this.$route.params.id;

      const params = {
        id: videoId,
        from: this.startDate, //this.fromDate,
        to: this.endDate, //this.toDate,
      };
      this.$store
        .dispatch("videos/GET_PURCHASE_HISTORY", params)
        .then(res => {
          const orderData = res.data.data.sort(
            (a, b) =>
              new Date(b.createdDateTime).getTime() -
              new Date(a.createdDateTime).getTime()
          );

          const orderHistory = orderData.map(order => {
            return {
              id: order.id.slice(-8),
              datePurchased: moment
                .utc(order.createdDateTime)
                .format("DD/MMM/YYYY"),
              user: `${order.meta.customer.firstName} ${order.meta.customer.lastName}`,
              price: `${
                order.meta.payment.currency
                  ? getSymbolFromCurrency(order.meta.payment.currency)
                  : getSymbolFromCurrency("GBP")
              } ${order.meta.payment.totalPrice}`,
              receipt: order.meta.payment.receipt,
            };
          });

          this.purchaseHistory = orderHistory;
          this.filteredList = this.purchaseHistory;

          this.activitiesColumns = [
            { field: "id", header: "Order Id", sortable: true },
            {
              field: "datePurchased",
              header: "Date of Purchase",
              sortable: true,
            },
            { field: "user", header: "User" },
            {
              field: "price",
              header: "Price",
              sortable: true,
            },
            {
              field: "receipt",
              header: "Action",
              icon: "fa fa-eye mr-2",
              label: "View",
            },
          ];
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>
