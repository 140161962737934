<template>
  <div
    class="middle-box text-center loginscreen animated fadeInDown d-flex vh-100 justify-content-center align-items-center pt-0 login-page"
  >
    <div>
      <div class="mb-4">
        <router-link to="/">
          <img src="~@/assets/images/logo.png" alt="Box Office VR" />
        </router-link>
      </div>
      <h3 class="mb-4">FORGOT YOUR PASSWORD?</h3>

      <p>
        No worries! Please enter your registered email address and we will send
        you a link to reset your password.
      </p>

      <!-- form -->
      <validation-observer ref="forgotPwdFrm">
        <b-form class="mt-2" @submit.prevent="forgotPassword">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="forgot-password-email"
                v-model="userName"
                :state="errors.length > 0 ? false : null"
                name="forgot-password-email"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button type="submit" variant="primary" block>
            Send reset link
          </b-button>
        </b-form>
      </validation-observer>

      <p class="text-center mt-2">
        <b-link :to="{ name: 'login' }">
          <feather-icon icon="ChevronLeftIcon" /> Back to login
        </b-link>
      </p>

      <p class="m-t">
        <small>Box Office VR © {{ year }}</small>
      </p>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BForm, BFormGroup, BFormInput, BButton, BLink } from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userName: "",
      required,
      email,
    };
  },
  mounted() {},
  computed: {
    year: () => new Date().getFullYear(),
  },
  methods: {
    async forgotPassword() {
      try {
        const success = await this.$refs.forgotPwdFrm.validate();
        if (success) {
          await this.$store.dispatch("auth/forgotPassword", this.userName);
          // TODO: notify user
          this.$router.push({ name: "signin" });
        }
      } catch (err) {
        this.showError(err);
      }
    },
    showError(err) {
      this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
    },
  },
};
</script>
