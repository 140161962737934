import axios from "@/http/axios";

const orderServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
};

const users = {
  namespaced: true,
  state: {
    usersList: [],
    userDetail: {},
    countries: [],
  },
  actions: {
    GET_USERS({ commit, dispatch }) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const url = `/user/manage`;
      axios
        .get(url)
        .then(resp => {
          commit("setUsers", resp.data);
          dispatch("general/SET_DATALOADING", false, { root: true });
        })
        .catch(error => {
          console.log(error);
          dispatch("general/SET_DATALOADING", false, { root: true });
        });
    },
    GET_USER_DETAIL({ commit, dispatch }, id) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const url = `/user/manage/${id}`;
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(resp => {
            // const data = resp.data.find(u => u.id == id);
            commit("setUserDetail", resp.data);
            resolve(resp.data);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(error => {
            console.log(error);
            reject(error);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    SEARCH_USER_BY_FULLNAME({ commit, dispatch }, fullName) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const url = `/user/manage/searchByFullName/${fullName}`;
      axios
        .get(url)
        .then(resp => {
          commit("setUsers", resp.data);
          dispatch("general/SET_DATALOADING", false, { root: true });
        })
        .catch(error => {
          console.log(error);
          dispatch("general/SET_DATALOADING", false, { root: true });
        });
    },
    UPDATE_USER_TO_DYNAMO({ commit }, userData) {
      const url = "/user/manage";
      return new Promise((resolve, reject) => {
        axios
          .put(url, userData)
          .then(res => {
            const result = res.data;
            console.log("update user : " + res.data);
            commit("setUserDetail", res.data);
            resolve(result);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DELETE_USER_FROM_DYNAMO({ commit }, id) {
      const url = `/user/manage/${id}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(url)
          .then(res => {
            const result = res.data;
            commit("setUserDetail", result);
            resolve(result);
          })
          .catch(e => {
            console.log("Error delete user", e);
            reject(e);
          });
      });
    },
    GET_PURCHASE_HISTORY(store, params) {
      const qs = `?from=${params.from}&to=${params.to}`;
      const url = "/order/customer/" + params.id + qs;
      return axios.get(url, { headers: orderServiceReqHeaders });
    },
    GET_COUNTRY_LIST({ commit }) {
      axios.get('/user/countries').then(function (res) {
        commit("setCountries", res.data);
      }, function(err) {
        console.log(err);
      });
    }
  },
  mutations: {
    setUsers(state, payload) {
      state.usersList = payload;
    },
    setUserDetail(state, payload) {
      state.userDetail = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
  },
  getters: {
    getUsers(state) {
      return state.usersList;
    },
    getUserDetail(state) {
      return state.userDetail;
    },
  },
};

export default users;
