<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element" v-if="user">
            <!-- <avatar :username="user['custom:firstName']"></avatar> -->
            <a data-toggle="dropdown" class="dropdown-toggle" href="#">
              <span class="block m-t-xs font-bold name-container">
                {{ user["custom:firstName"] }}
              </span>
              <span class="text-muted text-xs email block">
                {{ user["email"] }}
              </span>
              <span class="text-muted text-xs block">
                {{ user["custom:role"] }}
              </span>
            </a>
            <!--
            <ul class="dropdown-menu animated fadeInRight m-t-xs">
              <li>
                <a class="dropdown-item" href="profile.html">Profile</a>
              </li>
              <li>
                <a class="dropdown-item" href="contacts.html">Contacts</a>
              </li>
              <li>
                <a class="dropdown-item" href="mailbox.html">Mailbox</a>
              </li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="login.html">Logout</a></li>
            </ul>
            -->
          </div>
          <div class="logo-element">
            <img
              src="~@/assets/images/logo-small.png"
              class="logo-small"
              alt="Box Office VR"
            />
          </div>

          <div class="btn btn-close d-block d-md-none" @click="closeMobileMenu">
            <i class="fa fa-close"></i>
          </div>
        </li>
        <li>
          <a href="javascript:;" @click="goto('/')">
            <i class="fa fa-th-large"></i>
            <span class="nav-label">Dashboard</span>
          </a>
        </li>
        <li :class="isMenuManagementActive ? `active` : ``">
          <a href="javascript:;" @click="toggleMenu('management')"
            ><i class="fa fa-files-o"></i>
            <span class="nav-label">Management</span>
            <span class="fa arrow"></span
          ></a>
          <!-- When parent active, give these clases nav nav-second-level collapse in -->
          <transition name="fade">
            <ul
              class="nav nav-second-level nav nav-second-level collapse"
              :class="isMenuManagementActive ? `in` : ``"
              v-if="isMenuManagementActive"
            >
              <li>
                <a href="javascript:;" @click="goto('/featured')">Featured</a>
              </li>
              <li>
                <a href="javascript:;" @click="goto('/venues')">Venues</a>
              </li>
              <li>
                <a href="javascript:;" @click="goto('/videos')">Videos</a>
              </li>
              <li><a href="javascript:;" @click="goto('/users')">Users</a></li>
            </ul>
          </transition>
        </li>

        <li :class="isMenuContentActive ? `active` : ``">
          <a href="javascript:;" @click="toggleMenu('content')"
            ><i class="fa fa-pencil-square-o"></i>
            <span class="nav-label">Content</span>
            <span class="fa arrow"></span
          ></a>
          <!-- When parent active, give these clases nav nav-second-level collapse in -->
          <transition name="fade">
            <ul
              class="nav nav-second-level nav nav-second-level collapse"
              :class="isMenuContentActive ? `in` : ``"
              v-if="isMenuContentActive"
            >
              <li><a href="javascript:;" @click="goto('/faq')">Faq</a></li>
            </ul>
          </transition>
        </li>

        <li>
          <a href="#"
            ><i class="fa fa-bar-chart-o"></i>
            <span class="nav-label">Analytics</span>
          </a>
        </li>
        <li>
          <a href="#"
            ><i class="fa fa-cog"></i>
            <span class="nav-label">Settings</span>
          </a>
        </li>
        <li>
          <a href="javascript:;" @click="goto('/support')">
            <i class="fa fa-question-circle-o"></i>
            <span class="nav-label">Support</span>
          </a>
        </li>
        <li>
          <a href="javascript:;" @click="goto('/signout')">
            <i class="fa fa-sign-out"></i>
            <span class="nav-label">Sign out</span>
          </a>
        </li>

        <li class="landing_link">
          <a
            target="_blank"
            href="https://42interactive.com"
            aria-expanded="false"
            ><i class="fa fa-star"></i>
            <span class="nav-label">Landing Page</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// import Avatar from "@/components/Avatar";

export default {
  computed: {
    ...mapState({
      menuActive: (state) => state.general.menuActive, // array
    }),
    ...mapGetters({ user: "auth/getUser" }),
    isMenuManagementActive() {
      return this.menuActive.includes("management");
    },
    isMenuContentActive() {
      console.log("check isMenuContentActive", this.menuActive);
      return this.menuActive.includes("content");
    },
  },
  methods: {
    toggleMenu(menuId) {
      this.$store.dispatch("general/TOGGLE_MENU", menuId);
    },
    closeMobileMenu() {
      this.$store.dispatch("general/SET_MOBILENAVBAR", false);
    },
    goto(route) {
      console.log("check goto", route);
      this.closeMobileMenu();
      this.$router.push(route);
    },
  },
  components: {
    // Avatar,
  },
};
</script>

<style lang="scss">
.logo-small {
  width: 40px;
  height: 40px;
  background: white;
}
.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name-container {
  white-space: inherit;
}
</style>
