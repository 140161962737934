import axios from "axios";
import AuthTokens from '@/models/AuthTokens';
import CognitoApiProxy from '../../services/cognito-api';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  async config => {

    if (config.baseURL.indexOf(".boxofficevr.com") > -1) {
      const rawTokens = JSON.parse(localStorage.getItem("tokens"));
      const tokens = Object.keys(rawTokens).length > 0  ? new AuthTokens(rawTokens) : null;

      if (tokens) {
        if (tokens.idTokenExpiry - 120 <= Date.now() / 1000) {
          try {
            const newTokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration()
            
            if (newTokens) {
              console.log("tokens renewed");
              localStorage.setItem("tokens", JSON.stringify(newTokens));
            }
          } catch(err) {
            console.error(err);
            localStorage.clear();
            window.location.href = "/signin";
          }
        }

        config.headers.common["Authorization"] = tokens.idToken || "";
        return config;
      }

      window.location.href = "/signin";
      return;
    }

    return config;
  }, error => {
    return Promise.reject(error);
  }
);

export const mocks = axios.create({
  baseURL: "/data"
});

export default instance;
