export default class AuthTokenClaims {
  constructor(claims = {}) {
    this.aud = claims.aud;
    this.userName = claims["cognito:username"];
    this.firstName = claims["custom:firstName"];
    this.lastName = claims["custom:lastName"] || "";
    this.role = claims["custom:role"];
    this.email = claims.email
    this.email_verified = claims.email_verified
    this.exp = claims.exp
    this.iat = claims.iat;
    this.iss = claims.iss;
  }
}