<template>
  <div class="videos-page">
    <page-heading>Users Management</page-heading>

    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end text-right pb-2">
          <!-- <div class="mr-3">
            <button
              class="btn btn-lg btn-primary"
              @click="
                $router.push({
                  name: 'addUser',
                })
              "
            >
              Add user
            </button>
          </div> -->
          <form-search v-model="searchQuery" @onSearch="onSearch" />
        </div>
        <small-loader class="d-block mx-auto" v-if="dataLoading" />
        <data-table
          v-if="!dataLoading"
          :dataArray="usersList"
          :columns="columns"
          :actionLink="actionLink"
        ></data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PageHeading from "@/components/pageComponents/PageHeading";
import SmallLoader from "@/components/SmallLoader";
import DataTable from "@/components/datatable/datatable";
import FormSearch from "@/components/form/search";

export default {
  data() {
    return {
      breadcrumbs: [
        { title: "Home", route: "/" },
        { title: "Users", route: "/users" },
      ],
      columns: null,
      actionLink: "userDetail",
      searchQuery: null,
    };
  },
  created() {
    this.$store.dispatch("users/GET_USERS");
    this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);

    this.columns = [
      {
        field: "fullName",
        header: "Full Name",
        sortable: true,
      },
      { field: "email", header: "Email Address", sortable: true },
      // { field: "lastPurchase", header: "Last Purchase", sortable: true },
      // { field: "totalSpent", header: "Total Spent($)", sortable: true },
      { field: "", header: "Action" },
    ];
  },
  computed: {
    ...mapState({
      usersList: state => state.users.usersList,
      dataLoading: state => state.general.dataLoading,
    }),
    ...mapGetters(["getUsers"]),
  },
  methods: {
    onSearch: function() {
      //To do search
      console.log(this.searchQuery);
      if (!this.searchQuery) this.$store.dispatch("users/GET_USERS");
      this.$store.dispatch("users/SEARCH_USER_BY_FULLNAME", this.searchQuery);
    },
    getPurchaseHistory(userId) {
      const params = {
        id: userId,
        from: null, //this.fromDate,
        to: null, //this.toDate,
      };
      this.$store
        .dispatch("users/GET_PURCHASE_HISTORY", params)
        .then(res => {
          const purhaceHistory = res.data.data;
          console.log("Purchase history", purhaceHistory);
          //this.mapDataToUi(purhaceHistory);
          //this.$store.commit("venues/setPurchaseHistory", purhaceHistory);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  components: {
    PageHeading,
    SmallLoader,
    DataTable,
    FormSearch,
  },
};
</script>
