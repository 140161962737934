import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useVideoListing() {
  const refVideosTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { field: "title", header: "Title", sortable: true },
    { field: "vendor", header: "Vendor", sortable: true },
    { field: "totalRevenue", header: "Total Revenue (£)", sortable: true },
    { field: "totalViews", header: "Total Views", sortable: true },
    { field: "", header: "Action" },
  ];

  const statisticsItems = [
    {
      icon: "TrendingUpIcon",
      color: "light-primary",
      title: "890",
      subtitle: "Tickets Sold",
      customClass: "mb-2 mb-xl-0",
    },
    {
      icon: "PlayCircleIcon",
      color: "light-info",
      title: "1201",
      subtitle: "Play",
      customClass: "mb-2 mb-xl-0",
    },
    {
      icon: "DollarSignIcon",
      color: "light-success",
      title: "$15000",
      subtitle: "Revenue",
      customClass: "",
    },
  ];

  const perPage = ref(10)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const refetchData = () => {
    refVideosTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchVideos = (ctx, callback) => {
    // await store.dispatch('videoStore/GET_VIDEOS');
    // {
    //   q: searchQuery.value,
    //   perPage: perPage.value,
    //   page: currentPage.value,
    //   sortBy: sortBy.value,
    //   sortDesc: isSortDirDesc.value,
    //   status: statusFilter.value,
    // })
    // )
    store.dispatch("videos/GET_VIDEOS");
    store.dispatch("videos/GET_VIDEOS_STATISTIC");

    const { videos } = store.getters['videos/getVideos'];

    callback(videos);
  };

  return {
    fetchVideos,
    tableColumns,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVideosTable,
    statusFilter,
    refetchData,
    statisticsItems,
  };
}
