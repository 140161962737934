<template>
  <div
    class="middle-box text-center loginscreen animated fadeInDown d-flex vh-100 justify-content-center align-items-center pt-0 login-page"
  >
    <b-container fluid="md">
      <div class="mb-4">
        <router-link to="/">
          <img src="~@/assets/images/logo.png" alt="Box Office VR" />
        </router-link>
      </div>
      <h3 class="mb-4">Confirm Password</h3>
      <p>Please enter and confirm your new password.</p>

      <!-- form -->
      <validation-observer ref="confirmPwdFrm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="confirmPassword">
          <!-- password -->
          <b-form-group label="New Password" label-for="reset-password-new">
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-new"
                  v-model="password"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="reset-password-new"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="toggleNewPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group
            label-for="reset-password-confirm"
            label="Confirm Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-confirm"
                  v-model="confirmationPassword"
                  :type="confirmationPasswordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false : null"
                  name="reset-password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmationPasswordToggleIcon"
                    @click="toggleConfirmationPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit button -->
          <b-button type="submit" variant="primary" block :disabled="invalid">
            Set New Password
          </b-button>
        </b-form>
      </validation-observer>

      <p class="m-t">
        <small>Box Office VR © {{ year }}</small>
      </p>
    </b-container>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BContainer,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import { required, password } from "@validations";

export default {
  name: "Login",
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BContainer,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      password,
      confirmationPassword: "",
      userName: this.$route.query.u,
      code: this.$route.query.c,

      // Toggle Password
      passwordFieldType: "password",
      confirmationPasswordFieldType: "password",
    };
  },
  mounted() {},
  computed: {
    year: () => new Date().getFullYear(),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmationPasswordToggleIcon() {
      return this.confirmationPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    toggleNewPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    toggleConfirmationPasswordVisibility() {
      this.confirmationPasswordFieldType =
        this.confirmationPasswordFieldType === "password" ? "text" : "password";
    },
    async confirmPassword() {
      try {
        const success = await this.$refs.confirmPwdFrm.validate();
        if (success) {
          const payload = {
            email: this.userName,
            code: this.code,
            newPassword: this.confirmationPassword,
          };
          await this.$store.dispatch("auth/confirmPassword", payload);
          // TODO: notify user
          this.$router.push({ name: "signin" });
        }
      } catch (err) {
        this.showError(err);
      }
    },
    showError(err) {
      this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
    },
  },
};
</script>
