import axios from "@/http/axios";

const FEATURED_MANAGEMENT_BASEPATH = "/video/manage";

const featured = {
  namespaced: true,
  state: {
    featuredVideos: [],
  },
  mutations: {
    addVideo(state, item) {
      state.videos.unshift(item);
    },
    updateVideo(state, item) {
      const idx = state.videos.findIndex(v => v.id === item.id);
      if (idx > -1) {
        Object.assign(state.videos[idx], item);
      } else {
        state.videos.unshift(item);
      }
    },
    setFeaturedVideos(state, items) {
      state.featuredVideos = items;
    },
  },
  actions: {
    SET_FEATURED: ({ dispatch, commit }, items) => {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        axios
          .post(`${FEATURED_MANAGEMENT_BASEPATH}/featured`, items)
          .then(res => {
            console.log("result post feature", res);
            commit("setFeaturedVideos", res.data);
            resolve(res);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(err);
          });
      });
    },
    GET_FEATURED: ({ dispatch, commit }) => {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        axios
          .get(`${FEATURED_MANAGEMENT_BASEPATH}/featured`)
          .then(res => {
            console.log("result get featured", res);
            commit("setFeaturedVideos", res.data);
            resolve(res.data);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(err);
          });
      });
    },
  },
  getters: {
    getFeaturedVideos(state) {
      return state.featuredVideos;
    },
  },
};

export default featured;
