<template>
  <div>
    <h2 class="mb-5">Artwork Files</h2>

    <small-loader class="d-block mx-auto" v-if="loading" />

    <b-card class="mb-3">
      <h3>
        Featured Images
      </h3>
      <b-card-text>
        <b-row>
          <b-col md="12">
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>Large</label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image will be use on featured video expand homepage."
              >
              </i>
              <file-upload
                ref="featured_lg"
                name="featured_lg"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="false"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <img
                  v-if="video.artworks.featured_lg"
                  :src="`${video.artworks.featured_lg}?${new Date().getTime()}`"
                />
                <span v-else>No image.</span>
              </div>
            </b-form-group>
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>Small</label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image will be use on featured video collapse homepage, video detail similar shows."
              >
              </i>
              <file-upload
                ref="featured_sm"
                name="featured_sm"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="false"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <img
                  v-if="video.artworks.featured_sm"
                  :src="`${video.artworks.featured_sm}?${new Date().getTime()}`"
                />
                <span v-else>No image.</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-card class="mb-3">
      <h3>Slider</h3>
      <b-card-text>
        <b-row>
          <b-col md="12">
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>Portrait (only 1 image)</label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image/s will be use on now showing homepage."
              >
              </i>
              <file-upload
                ref="slider_p"
                name="slider_p"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="false"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <template v-if="video.artworks.slider_p">
                  <div
                    v-for="(item, idx) of video.artworks.slider_p"
                    :key="idx"
                    class="d-inline-flex mb-2 mr-2"
                  >
                    <img :src="`${item}?${new Date().getTime()}`" />
                  </div>
                </template>
                <span v-else>No image.</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>Landscape (can be more than 1 images)</label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image/s will be use on video detail for hero slider."
              >
              </i>
              <file-upload
                ref="slider_l"
                name="slider_l"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="true"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <template v-if="video.artworks.slider_l">
                  <div
                    v-for="(item, idx) of video.artworks.slider_l"
                    :key="idx"
                    class="d-inline-flex mb-2 mr-2"
                  >
                    <img :src="`${item}?${new Date().getTime()}`" />
                  </div>
                </template>
                <span v-else>No image.</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-card class="mb-3">
      <h3>Native App Images</h3>
      <b-card-text>
        <b-row>
          <b-col md="12">
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>
                Order Item Image (only 1 image) <br />
                Best size ratio 1840:900 pixels
              </label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image/s will be used on Native App Order Page."
              >
              </i>
              <file-upload
                ref="native_app_order"
                name="native_app_order"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="false"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <img
                  v-if="video.artworks.native_app_order"
                  :src="
                    `${video.artworks.native_app_order}?${new Date().getTime()}`
                  "
                />
                <span v-else>No image.</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
              <label>
                Show Detail Image (only 1 image)<br />
                Best size ratio 1920:1790 pixels
              </label>
              <i
                class="ml-2 fa fa-info-circle"
                v-b-tooltip.hover
                title="This image will be used on Native App Show Detail page."
              >
              </i>
              <file-upload
                ref="native_app_detail"
                name="native_app_detail"
                :customUpload="true"
                @uploader="uploadArtworks"
                :multiple="false"
                ><template #empty>
                  <p>Drag and drop files to here to upload</p>
                </template></file-upload
              >
              <div class="image-container" v-if="video.artworks">
                <img
                  v-if="video.artworks.native_app_detail"
                  :src="
                    `${
                      video.artworks.native_app_detail
                    }?${new Date().getTime()}`
                  "
                />
                <span v-else>No image.</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol, BFormGroup, BCardText } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SmallLoader from "@/components/SmallLoader";
import FileUpload from "@core/components/file-upload/FileUpload.vue";
import S3ApiProxy from "@/services/s3-api";

const s3 = new S3ApiProxy();

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    FileUpload,
    SmallLoader,
  },
  directives: {
    Ripple,
  },
  props: {
    video: {},
  },
  data() {
    return {
      artworks: { videoId: this.video.id, ...this.video.artworks },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.general.dataLoading,
    }),
  },
  mounted() {},
  methods: {
    async uploadArtworks(name, files) {
      try {
        const folderName = `artworks/${encodeURIComponent(this.video.id)}`;

        if (name.indexOf("slider_") > -1) {
          this.artworks[name] = [];
          const promises = [];

          let fileIdx = 0;
          files.forEach((file) => {
            const fileName = `${name}-${fileIdx++}${file.name.slice(
              file.name.lastIndexOf(".")
            )}`;
            promises.push(s3.uploadFile(file, fileName, folderName));
          });

          const result = await Promise.all(promises);
          this.artworks[name] = result;
        } else {
          const fileName = `${name}${files[0].name.slice(
            files[0].name.lastIndexOf(".")
          )}`;

          this.artworks[name] = await s3.uploadFile(
            files[0],
            fileName,
            folderName
          );
        }

        this.artworks.videoId = this.video.id;
        this.$store.dispatch("videoStore/UPDATE_VIDEO_ARTWORKS", this.artworks);

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Video artworks was updated successfully.",
          life: 3000,
        });

        // clear files after upload is success
        this.$refs[name].files = "";
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  img {
    max-height: 187px;
  }
}
</style>
