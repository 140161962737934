/* eslint-disable no-unused-vars */

const GENRE_ENUM = {
  Ballet: "Ballet",
  Cabaret: "Cabaret",
  Comedy: "Comedy",
  Concert: "Concert",
  ContemporaryDance: 'Contemporary Dance',
  Dance: "Dance",
  DanceTheatre: "Dance Theatre",
  FamilyTheatre: "Family Theatre",
  Festival: "Festival",
  Fringe: "Fringe",
  HipHopTheatre: "Hip Hop Theatre",
  Musical: "Musical",
  Opera: "Opera",
  Pantomime: "Pantomime",
  PhysicalTheatre: "Physical Theatre",
  Play: "Play",
  Puppet: "Puppet"
};

const GENRES = Object.keys(GENRE_ENUM).map(key => GENRE_ENUM[key]);

export {
  GENRES,
  GENRE_ENUM,
}