<template>
  <div class="videos-page">
    <page-heading>Videos Management</page-heading>
    <small-loader class="d-block mx-auto" v-if="loading" />
    <div v-else>
      <div class="row">
        <div class="col-12">
          <ListingSummary :summary="videosSummary" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ListingFilter
            @clearFilter="clearFilter"
            @doSearch="doSearch"
            @resetSearch="resetSearch"
            :showSearchResult.sync="showSearchResult"
            :filterKeyword.sync="filterKeyword"
            :searchKeyword.sync="searchKeyword"
          >
            <button
              class="btn btn-lg btn-primary"
              @click="
                $router.push({
                  name: 'addVideo',
                })
              "
            >
              Add Video
            </button>
          </ListingFilter>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <data-table
            :dataArray="filteredVideoList"
            :columns="tableColumns"
            :actionLink="actionLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListingSummary from "@/components/shared/ListingSummary";
import ListingFilter from "@/components/shared/ListingFilter";
import PageHeading from "@/components/pageComponents/PageHeading";
import DataTable from "@/components/datatable/datatable";
import SmallLoader from "@/components/SmallLoader";
import AlgoliaSearchApiProxy from "../../services/algolia-search-api";
import useVideoListing from "./useVideoListing";
// import Statistics from "./partials/Statistics.vue";

export default {
  components: {
    PageHeading,
    DataTable,
    SmallLoader,
    ListingSummary,
    ListingFilter,
    // Statistics,
  },
  data() {
    return {
      columns: null,
      actionLink: "videoDetail",
      breadcrumbs: [
        { title: "Home", route: "/" },
        { title: "Videos", route: "/videos" },
      ],
      filterKeyword: "",
      searchKeyword: "",
      showSearchResult: false,
      searchResult: null,
    };
  },
  created() {
    this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
    this.$store.dispatch("videos/GET_VIDEOS");
    this.$store.dispatch("videos/GET_VIDEOS_STATISTIC");
    
    this.searchService = new AlgoliaSearchApiProxy();
  },
  computed: {
    ...mapState({
      videoList: (state) => state.videos.videos,
        // state.videos.videos.map((item) => {
        //   const { venue: { name = "- venue not assigned -" } = {}} = item;
        //   item.venueName = name
        //   return item;
        // }),
      videosStatistic: (state) => state.videos.videosStatistic,
      loading: (state) => state.general.dataLoading,
    }),
    videosSummary() {
      return [ 
        { title: "NEW VIDEOS THIS MONTH", total: this.videosStatistic.totalVideosThisMonth },
        { title: "NEW VIDEOS THIS YEAR", total: this.videosStatistic.totalVideosThisYear },
        { title: "VIDEOS", total: this.videosStatistic.totalVideos}
      ]
    },
    filteredVideoList() {
      return this.listedVideos.filter(
        (item) =>
          item.title.toLowerCase().includes(this.filterKeyword.toLowerCase()) ||
          item.vendor.toLowerCase().includes(this.filterKeyword.toLowerCase())
      );
    },
    listedVideos() {
      return this.searchResult || this.videoList;
    }
  },
  methods: {
    clearFilter() {
      this.filterKeyword = "";
    },
    async doSearch() {
      if (this.searchKeyword != "") {
        this.searchResult = await this.searchService.query(this.searchKeyword);
        this.showSearchResult = true;
      }
    },
    resetSearch() {
      this.searchKeyword = "";
      this.$store.dispatch("venues/RESET_SEARCH");
      this.showSearchResult = false;
      this.searchResult = null;
    },
  },
  setup() {
    // const VIDEO_STORE_MODULE_NAME = "videoStore";

    // if (!store.hasModule(VIDEO_STORE_MODULE_NAME)) {
    //   store.registerModule(VIDEO_STORE_MODULE_NAME, videoStore);
    // }

    const {
      fetchVideos,
      tableColumns,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVideosTable,
      statusFilter,
      refetchData,
      statisticsItems,
      refVideos,
    } = useVideoListing();

    return {
      fetchVideos,
      tableColumns,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVideosTable,
      statusFilter,
      refetchData,
      statisticsItems,
      refVideos,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
