// import jwtDecode from 'jwt-decode';
import CognitoApiProxy from "../../services/cognito-api";
import AuthTokens from "@/models/AuthTokens";

export default {
  namespaced: true,
  state: {
    isSignedIn: false,
    userName: "",
    selectedUserAttribute: {},
  },
  mutations: {
    SIGNOUT(state) {
      state.isSignedIn = false;
      localStorage.clear();
    },
    setSelectedUserAttribute(state, payload) {
      state.selectedUserAttribute = payload;
    },
  },
  actions: {
    async signIn({ state, dispatch, commit }, authDetail) {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        const authService = new CognitoApiProxy();
        const response = await authService.signIn(
          authDetail.userName,
          authDetail.password
        );

        // If there's user data in response
        if (response.data) {
          const authTokens = new AuthTokens(response.data);

          const { claims = null } = authTokens;

          if (claims) {
            if (claims.role === "admins") {
              localStorage.setItem("tokens", JSON.stringify(authTokens));
              state.isSignedIn = true;
              state.userName = authTokens.claims.email;
              return;
            } else {
              commit("SIGNOUT");
              throw new Error(
                "Sorry, you are not authorized to access this site."
              );
            }
          }
        }

        throw new Error("Incorrect Username or Password");
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    async forgotPassword(store, email) {
      const authService = new CognitoApiProxy();
      await authService.forgotPassword(email);
    },
    async confirmPassword(store, { email, code, newPassword }) {
      const authService = new CognitoApiProxy();
      await authService.confirmPassword(email, code, newPassword);
    },
    async refreshAuthTokens({ dispatch }) {
      try {
        const tokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration();

        if (tokens) {
          localStorage.setItem("tokens", JSON.stringify(tokens));
        } else {
          throw new Error("401");
        }
      } catch (err) {
        dispatch("auth/signOut");
      }
    },
    signOut({ commit, state }) {
      new CognitoApiProxy().getCognitoUser(state.userName).signOut();
      commit("SIGNOUT");
    },
    async signUp(userData) {
      let response;
      const authService = new CognitoApiProxy();
      response = await authService.signUp(
        userData.email, //guid
        userData.password,
        [
          //{ email: userData.email },
          { "custom:firstName": userData.firstName },
          { "custom:lastName": userData.lastName },
          { "custom:role": userData.role },
        ]
      );

      if (response) {
        console.log("signup", response);
        return response;
      } else console.log("error: ", response.error.message);
    },
    async getUserAttributes({ commit, dispatch }, userId) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .getUserAttributes(userId)
          .then(res => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            commit("setSelectedUserAttribute", res);
            resolve(res);
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            console.log("get user attr error", err);
            if (err.message === "401")
              dispatch("auth/signOut");
            reject(err);
          });
      });
    },
    async updateUserToCognito({ dispatch }, userData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .updateUserAttributes(userData.userId, [
            //{ email: userData.email }, At the moment we can't change user email
            { Name: "custom:firstName", Value: userData.firstName },
            { Name: "custom:lastName", Value: userData.lastName },
          ])
          .then(res => {
            console.log("update user attr resp", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            console.log("update user attr error", err);
            if(err.message === "401")
              dispatch("auth/signOut")
            reject(err);
          });
      });
    },
    async resetUserPassword({ dispatch }, userId) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .resetUserPassword(userId)
          .then(res => {
            console.log("reset password resp", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            console.log("reset password err", err);
            reject(err);
          });
      });
    },
    deleteUser({ dispatch }, userId) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .deleteUser(userId)
          .then(res => {
            console.log("delete user resp", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            console.log("delete user err", err);
            reject(err);
          });
      });
    },
  },
  getters: {
    async isSignedIn() {
      try {
        const rawTokens = JSON.parse(localStorage.getItem("tokens")) || {};
        let tokens = Object.keys(rawTokens).length > 0  ? rawTokens : null;

        if (!tokens)
          return false;

        const { idTokenExpiry = Date.now() } = tokens || {};
        if (idTokenExpiry - 120 <= Date.now() / 1000) {

          tokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration()

          if (tokens) {
            localStorage.setItem("tokens", JSON.stringify(tokens));
          } else {
            localStorage.clear();
            return false;
          }
        }

        return tokens != null;
      } catch(err) {
        console.error(err);
        return false;
      }
    },
    getUser() {
      const { claims = null } =
        JSON.parse(localStorage.getItem("tokens")) || {};
      return claims ?? null;
    },
    getAuthTokens() {
      return new AuthTokens(JSON.parse(localStorage.getItem("tokens")));
    },
    getSelectedUserAttribute(state) {
      return state.selectedUserAttribute;
    },
  },
};
