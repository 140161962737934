<template>
  <div class="ibox">
    <div class="ibox-content">
      <DataTable class="p-datatable-responsive-demo" :value="dataArray">
        <Column
          v-for="col of columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :sortable="col.sortable"
          :icon="col.icon"
          :label="col.label"
        >
          <template #body="slotProps">
            <button
              v-if="col.header == 'Action'"
              class="btn btn-primary"
              @click="
                actionClick(
                  actionLink,
                  slotProps.data.id,
                  slotProps.data[col.field]
                )
              "
            >
              <i
                :class="
                  col.icon && col.icon !== '' ? col.icon : 'fa fa-pencil mr-2'
                "
                >&nbsp;</i
              >{{ col.label && col.label !== "" ? col.label : "Edit" }}
            </button>
            <div v-else>
              <span class="p-column-title">{{ col.header }}</span>
              {{ slotProps.data[col.field] }}
            </div>
          </template>
        </Column>
        <template #loading>
          Loading records, please wait...
        </template>
        <template #empty>
          No records found.
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  props: {
    dataArray: null,
    columns: null,
    actionLink: null, // route name
  },
  data() {
    return {};
  },
  components: {
    DataTable,
    Column,
  },
  methods: {
    actionClick(link, id, fieldValue) {
      if (link == "EXTERNAL_LINK") {
        console.log("actionClick", fieldValue);
        var win = window.open(fieldValue, "_blank");
        win.focus();
      } else {
        this.$router.push({ name: link, params: { id } });
      }
    },
  },
};
</script>

<style lang="scss">
.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
</style>
