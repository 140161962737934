<template>
  <div class="video-detail-page">
    <page-heading>
      {{ video === null ? "Video Details" : video.title }}
    </page-heading>

    <small-loader class="d-block mx-auto" v-if="dataLoading" />
    <b-tabs class="tabs-container" v-else>
      <b-tab title="Details">
        <tab-details
          class="panel-body"
          :video="video"
          @videoUpdated="reassignVideoObj($event)"
        ></tab-details>
      </b-tab>
      <b-tab ref="videos" title="Video Files">
        <tab-videos class="panel-body" :video="video" v-if="video"></tab-videos>
      </b-tab>
      <b-tab ref="artworks" title="Artwork Files" v-if="video">
        <tab-artworks
          class="panel-body"
          :video="video"
          v-if="video"
        ></tab-artworks>
      </b-tab>
      <b-tab ref="activities" title="Activity" v-if="video">
        <tab-activities
          class="panel-body"
          :video="video"
          v-if="video"
        ></tab-activities>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import videoStore from "./store";
import PageHeading from "@/components/pageComponents/PageHeading";
import { BTabs, BTab } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import venueStore from "../venues/store";
import SmallLoader from "@/components/SmallLoader";
import { TabDetails, TabVideos, TabArtworks, TabActivities } from "./partials";

export default {
  components: {
    PageHeading,
    BTabs,
    BTab,
    SmallLoader,
    TabDetails,
    TabVideos,
    TabArtworks,
    TabActivities,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      video: {
        id: null,
        title: "",
      },
    };
  },
  async mounted() {
    if (!videoStore.isRegistered) {
      this.$store.registerModule("videoStore", videoStore);
      videoStore.isRegistered = true;
    }

    if (!venueStore.isRegistered) {
      this.$store.registerModule("venueStore", venueStore);
      venueStore.isRegistered = true;
    }

    this.$store.dispatch("venueStore/GET_VENUES", "id,name,city,country");

    if (this.$route.params.id) {
      this.video = await this.$store.dispatch(
        "videoStore/GET_VIDEO_DETAIL",
        this.$route.params.id
      );

      if (!this.video.id) {
        this.$refs.videos.disabled = true;
        this.$refs.artworks.disabled = true;
        this.$refs.activities.disabled = true;
      }

      this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
    }
  },
  created() {},
  computed: {
    ...mapState({
      dataLoading: state => state.general.dataLoading,
    }),
    breadcrumbs: function() {
      return [
        { title: "Home", route: "/" },
        { title: "Videos", route: "/videos" },
        { title: this.video.title, route: null },
      ];
    },
  },
  methods: {
    reassignVideoObj: function(data) {
      this.video = data;
    },
  },
};
</script>
