<template>
  <div class="row">
    <div class="col-12">
      <h2 class="mb-5">Profile</h2>
      <b-card>
        <div class="form-group">
          <div>
            <ValidationObserver ref="userForm">
              <div class="form-group row">
                <div class="form-group col-sm-5">
                  <label class="col-form-label">First name<span>*</span></label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetail.firstName"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col-sm-5">
                  <label class="col-form-label">Last name<span>*</span></label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetail.lastName"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label"
                  >Email Address<span>*</span></label
                >
                <div class="col-sm-8">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <input
                      name="email"
                      type="text"
                      class="form-control"
                      v-model="userDetail.email"
                      disabled
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row" v-if="!createMember">
                <label class="col-sm-2 col-form-label">Password</label>
                <div class="col-sm-8">
                  <ConfirmationModal
                    ref="changePasswordModal"
                    title="Change Password?"
                    message="Are you sure you want to trigger change password for this user ? It will send email to user so they can create new password by themselves"
                    :actionAccept="confirmAcceptResetPassword"
                    :actionReject="confirmRejectResetPassword"
                    btnAcceptTitle="Reset password"
                    btnAcceptStyle="background-color:red;"
                    iconAccept="fa fa-check"
                  />
                  <button
                    class="btn btn-primary btn-lg"
                    @click="changePasswordPrompt"
                  >
                    <i class="fa fa-pencil mr-2"></i>Change password
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Date Of Birth</label>
                <div class="col-sm-8">
                  <Calendar
                    :value="userDetail.dateOfBirth"
                    @calendarSelected="updateDateOfBirth($event)"
                  ></Calendar>
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.dateOfBirth"
                  /> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Street Address</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.address"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Address Line 2</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.secondaryAddress"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">City</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.city"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Post Code</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.postCode"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">State</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetail.state"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Country</label>
                <div class="col-sm-8">
                  <select class="form-control" v-model="userDetail.country">
                    <option v-for="(item, index) in countries" v-bind:key="index" :value="item.code">
                      {{item.name}}
                    </option>
                  </select>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <span class="font-weight-bold">* required fields</span>
          <div class="hr-line-dashed"></div>
          <div class="form-group row">
            <div class="col-sm-4 col-sm-offset-2">
              <button class="btn btn-primary btn-lg mr-2" @click="saveOrUpdate">
                <i class="fa fa-arrow-down"></i>&nbsp;Save changes
              </button>
              <button
                class="btn btn-default btn-white btn-lg"
                @click="
                  $router.push({
                    name: 'users',
                  })
                "
              >
                <i class="fa fa-times"></i>&nbsp;Cancel
              </button>
            </div>
            <div v-if="!createMember" class="col-sm-8 text-right">
              <ConfirmationModal
                ref="deleteModal"
                title="Delete User?"
                message="Warning: This action is not reversible"
                :actionAccept="confirmDeleteAccept"
                :actionReject="confirmDeleteReject"
                btnAcceptStyle="background-color:red;"
              />
              <button class="btn btn-danger btn-lg" @click="deletePrompt">
                <i class="fa fa-trash-o"></i>&nbsp;Delete
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Calendar from "@/components/calendar/calendar";
import { mapState } from "vuex";

export default {
  props: ["id", "detail", "createMember", "userAttribute"],
  data() {
    return {
      userDetail: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        address: "",
        secondaryAddress: "",
        city: "",
        postCode: "",
        state: "",
        country: "",
      },
    };
  },
  components: {
    Calendar,
  },
  mounted() {
    if (!this.createMember) this.mapDataToUI(this.detail);
    console.log("User attribute", this.userAttribute);
  },
  computed: {
    ...mapState({
      countries: state => state.users.countries,
    }),
    isUpdateUser: function() {
      return this.id != null;
    },
  },
  methods: {
    mapDataToUI: function(detail) {
      this.userDetail.id = detail.id;
      this.userDetail.firstName = detail.firstName;
      this.userDetail.lastName = detail.lastName;
      this.userDetail.email = detail.email;
      this.userDetail.dateOfBirth = detail.dateOfBirth
        ? moment(detail.dateOfBirth).toDate()
        : null;
      this.userDetail.address = detail.address;
      this.userDetail.secondaryAddress = detail.secondaryAddress;
      this.userDetail.city = detail.city;
      this.userDetail.postCode = detail.postCode;
      this.userDetail.state = detail.state;
      this.userDetail.country = this.getSelectedCountryCode(detail.country);
      this.userDetail.fullName = detail.fullName;
    },
    saveOrUpdate: async function() {
      const success = await this.$refs.userForm.validate();
      if (success) {
        if (this.isUpdateUser) this.updateUser();
        else this.saveUser();
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Warning",
          detail: "* required fields",
          life: 3000,
        });
      }
    },
    updateDateOfBirth: function(date) {
      console.log("selected date", date);
      this.userDetail.dateOfBirth = date;
    },
    saveUser: function() {
      // To do Save user
    },
    updateUser: function() {
      if (
        this.userDetail.firstName !== this.userAttribute["custom:firstName"] ||
        this.userDetail.lastName !== this.userAttribute["custom:lastName"]
      ) {
        console.log("cognito update will trigger");
        this.saveToCognito();
      } else this.saveToDynamoDb();
    },
    saveToCognito: function() {
      const data = {
        userId: this.userDetail.id,
        firstName: this.userDetail.firstName,
        lastName: this.userDetail.lastName,
      };
      this.$store
        .dispatch("auth/updateUserToCognito", data)
        .then(() => {
          console.log("User updated successfully");
          this.$store.dispatch("auth/getUserAttributes", this.id); // Refetch the user data
          this.saveToDynamoDb();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong, please try again later.",
            life: 3000,
          });
        });
    },
    saveToDynamoDb: function() {
      this.userDetail.fullName = `${this.userDetail.firstName} ${this.userDetail.lastName}`;
      this.userDetail.country = this.getSelectedCountryObject(this.userDetail.country)
      this.$store
        .dispatch("users/UPDATE_USER_TO_DYNAMO", this.userDetail)
        .then((res) => {
          if (res) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "User updated successfully.",
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("Error when update user", err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong, please try again later.",
            life: 3000,
          });
        });
    },
    changePasswordPrompt: function() {
      this.$refs.changePasswordModal.show();
    },
    confirmAcceptResetPassword: function() {
      // To do Change password
      this.$store
        .dispatch("auth/resetUserPassword", this.id)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail:
              "Success. Email for reset password has been sent to the user.",
            life: 3000,
          });
        })
        .catch((err) => {
          console.log("Error when reset password", err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong, please try again later.",
            life: 3000,
          });
        });
    },
    confirmRejectResetPassword: function() {
      // Do nothing
    },
    deletePrompt: function() {
      this.$refs.deleteModal.show();
    },
    confirmDeleteAccept: function() {
      this.$store
        .dispatch("auth/deleteUser", this.id)
        .catch((err) => {
          console.log("Error when delete user", err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong, please try again later.",
            life: 3000,
          });
        });
      this.softDeleteFromDynamo();
    },
    confirmDeleteReject: function() {
      // Do nothing
    },
    softDeleteFromDynamo() {
      this.$store
        .dispatch("users/DELETE_USER_FROM_DYNAMO", this.id)
        .then((res) => {
          if (res) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "User deleted successfully.",
              life: 3000,
            });
            this.$router.push("/users");
          }
        })
        .catch((err) => {
          console.log("error delete from dynamo", err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong, please try again later.",
            life: 3000,
          });
        });
    },
    getSelectedCountryObject(code) {
      let country = this.countries.find(item => item.code === code)
      if (country) {
        return JSON.stringify(country)
      }
      return ''
    },
    getSelectedCountryCode(object) {
      let country = this.countries.find(item => JSON.stringify(item) === object)
      if (country) {
        return country.code
      }
      return ''
    }
  },
};
</script>
