<template>
  <div class="month-filter-container">
    <div class="form-group row">
      <div class="col-sm-2">
        <select class="form-control" v-model="currentMonth">
          <option
            :key="idx"
            v-for="(item, idx) in months"
            :value="item.value"
            >{{ item.label }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    isAutoSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      months: [],
      currentMonth: null,
    };
  },
  created() {
    this.resolveMonthOptions();
  },
  mounted() {
    // auto select current month
    if (this.isAutoSelect) {
      const month = moment(new Date()).format("M");
      this.currentMonth = month;
    }
  },
  watch: {
    currentMonth(val) {
      if (val) this.setStartEndDate(this.currentMonth);
    },
  },
  methods: {
    resolveMonthOptions: function() {
      this.months = moment.monthsShort().map((m, idx) => {
        return {
          label: m,
          value: idx + 1,
        };
      });
    },
    setStartEndDate: function(month) {
      const date = moment().month(month - 1, "ddd MMM DD YYYY");
      const firstdate = moment(date)
        .startOf("month")
        .format("YYYY-MM-DD");

      const lastdate = moment(date)
        .endOf("month")
        .format("YYYY-MM-DD");

      let startEndDate = {
        startDate: firstdate,
        lastDate: lastdate,
      };

      this.$emit("startEndDate", startEndDate);
    },
  },
};
</script>
