/* eslint-disable no-unused-vars */
import AWS from "aws-sdk";
import {
  CognitoUserPool,
  CognitoAccessToken,
  CognitoUser,
  CognitoIdToken,
  CognitoRefreshToken,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import AuthTokens from "../models/AuthTokens";

export default class CognitoApiProxy {
  constructor({
    awsRegion = process.env.VUE_APP_COGNITO_AWS_REGION,
    cognitoUserPoolId = process.env.VUE_APP_COGNITO_USER_POOL_ID,
    cognitoIdentityPoolId = process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    cognitoClientId = process.env.VUE_APP_COGNITO_CLIENT_APP_ID,
  } = {}) {
    if (!cognitoClientId) throw new Error("Cognito Client App ID is required");
    if (!awsRegion) throw new Error("AWS Cognito Region is required");
    if (!cognitoUserPoolId) throw new Error("Cognito User Pool ID is required");
    if (!cognitoIdentityPoolId)
      throw new Error("Cognito Identity Pool ID is required");

    this.awsRegion = awsRegion;
    this.cognitoUserPoolId = cognitoUserPoolId;
    this.cognitoClientId = cognitoClientId;
    this.cognitoIdentityPoolId = cognitoIdentityPoolId;

    this.poolInfo = {
      UserPoolId: this.cognitoUserPoolId,
      ClientId: this.cognitoClientId,
    };

    this.cognitoAttributeList = [];

    this.attributes = (key, value) => ({
      Name: key,
      Value: value,
    });

    AWS.config.region = this.awsRegion;

    this.initAWS();
  }

  checkAndRenewTokenForExpiration() {
    return new Promise((resolve, reject) => {
        let tokens = null;
        let cognitoUser = null;
        let refreshToken = null;


        try {
          tokens = new AuthTokens(JSON.parse(localStorage.getItem("tokens")));
          refreshToken = new CognitoRefreshToken({
            RefreshToken: tokens.refreshToken,
          });

          cognitoUser = this.getCognitoUser(tokens.claims.email);
        } catch {
          reject(new Error("401"));
        }

        cognitoUser.refreshSession(refreshToken, (err, session) => {
          console.log(err, session);

          if (err || !session) {
            console.log(err);
            reject(new Error("401"));
          }

          const newTokens = this.getTokens(session);
          AWS.config.credentials = this.getCognitoIdentityCredentials(newTokens);

          resolve(new AuthTokens(newTokens));
        });
    });
  }

  setCognitoAttributeList(email, attrs) {
    // eslint-disable-next-line prefer-const
    let attributeList = [];
    if (email) attributeList.push(this.attributes("email", email));
    attrs.map(attr =>
      attributeList.push(
        this.attributes(Object.keys(attr)[0], Object.values(attr)[0])
      )
    );

    attributeList.map(attr =>
      this.cognitoAttributeList.push(new CognitoUserAttribute(attr))
    );
  }

  getUserPool() {
    return new CognitoUserPool(this.poolInfo);
  }

  getCognitoUser(userId) {
    //In Cognito, username is using User Id
    const userData = {
      Username: userId,
      Pool: this.getUserPool(),
    };
    return new CognitoUser(userData);
  }

  getAuthDetails(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };

    return new AuthenticationDetails(authenticationData);
  }

  initAWS() {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.cognitoIdentityPoolId,
    });
  }

  getTokens(session) {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
      idTokenExpiry: session.getIdToken().decodePayload().exp,
      claims: session.getIdToken().decodePayload(),
    };
  }

  getCognitoIdentityCredentials(tokens) {
    const loginInfo = {};
    loginInfo[
      `cognito-idp.${this.awsRegion}.amazonaws.com/${this.cognitoUserPoolId}`
    ] = tokens.idToken;
    const params = {
      IdentityPoolId: this.cognitoIdentityPoolId,
      Logins: loginInfo,
    };
    return new AWS.CognitoIdentityCredentials(params);
  }

  getCognitoIdentityServiceProvider() {
    return new Promise((resolve, reject) => {
      try {
        const tokens = new AuthTokens(JSON.parse(localStorage.getItem("tokens")));
        if (tokens && tokens.idTokenExpiry - 120 <= Date.now() / 1000) {
          this.checkAndRenewTokenForExpiration()
            .then(res => {
              localStorage.setItem("tokens", JSON.stringify(res));

              resolve(new AWS.CognitoIdentityServiceProvider({
                region: this.awsRegion,
                credentials: this.getCognitoIdentityCredentials(res),
              }));
            })
            .catch(err => {
              console.log("error when renew token", err);
              reject(new Error("401"));
            });
        } else {
          resolve(new AWS.CognitoIdentityServiceProvider({
            region: this.awsRegion,
            credentials: this.getCognitoIdentityCredentials(
              JSON.parse(localStorage.getItem("tokens"))
            ),
          }));
        }
      } catch(err) {
        reject(new Error("401"));
      }
    });
  }

  getAWSCredentials(credentials) {
    return {
      accessKey: credentials.AccessKeyId,
      secretKey: credentials.SecretKey,
      sessionToken: credentials.SessionToken,
      region: this.awsRegion,
    };
  }

  // function decodeJWTToken(token) {
  //   const {  email, exp, authTime , tokenUse, sub} = jwtDecode(token.idToken);
  //   return {  token, email, exp, uid: sub, authTime, tokenUse };
  // }

  signUp(userName, password, attributes) {
    return new Promise((resolve, reject) => {
      this.setCognitoAttributeList(userName, attributes);

      this.getUserPool().signUp(
        userName,
        password,
        this.cognitoAttributeList,
        null,
        (err, result) => {
          if (err) {
            reject({ statusCode: 422, response: err });
          }
          if (result) {
            const response = {
              username: result.user.username,
              userConfirmed: result.userConfirmed,
              userAgent: result.user.client.userAgent,
              cognitoUserId: result.userSub,
            };

            resolve({ statusCode: 201, response });
          }
        }
      );
    });
  }

  // verify(email, code) {
  //   return new Promise((resolve, reject) => {
  //     this.getCognitoUser(email).confirmRegistration(code, true, (err, result) => {
  //       if (err) {
  //         resolve({ statusCode: 422, response: err });
  //       }
  //       reject({ statusCode: 400, response: result });
  //     });
  //   });
  // }

  signIn(userName, password) {
    return new Promise((resolve, reject) => {
      const authDetail = this.getAuthDetails(userName, password);

      this.getCognitoUser(userName).authenticateUser(authDetail, {
        onSuccess: result => {
          const response = {
            accessToken: result.getAccessToken().getJwtToken(),
            idToken: result.getIdToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
            idTokenExpiry: result.getIdToken().getExpiration(),
            claims: result.getIdToken().payload,
          };

          const logins = {};
          (logins[
            `cognito-idp.${this.awsRegion}.amazonaws.com/${this.cognitoUserPoolId}`
          ] = result.getIdToken().getJwtToken()),
            (AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: this.cognitoIdentityPoolId,
              Logins: logins,
            }));

          AWS.config.credentials.refresh();

          // this.getCognitoUser(userName).setDeviceStatusRemembered({
          //   onSuccess: function(result) {
          //     console.log(result);
          //   },
          //   onFailure: function(err) {
          //     console.log(err);
          //   },
          // });
          resolve({ statusCode: 200, data: response });
        },
        onFailure: err => reject(err),
      });
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      this.getCognitoUser(email).forgotPassword({
        onSuccess: result => {
          if (!result) {
            reject(result);
          }
          resolve();
        },
        onFailure: err => reject(err),
      });
    });
  }

  confirmPassword(email, verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      this.getCognitoUser(email).confirmPassword(
        verificationCode,
        newPassword,
        {
          onSuccess: () => {
            resolve();
          },
          onFailure: err => reject(err),
        }
      );
    });
  }

  signOut(email) {
    return new Promise((resolve, reject) => {
      try {
        this.getCognitoUser(email).signOut();
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  getUserAttributes(userId) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userId,
        UserPoolId: this.getUserPool().getUserPoolId(),
      };
      
      this.getCognitoIdentityServiceProvider()
      .then(isp => {
        isp.adminGetUser(
          userData, (err, data) => {
            if (err) {
              reject(err);
            } else {
              let tempArr = [];
              data.UserAttributes.map(attr => {
                let key = Object.values(attr)[0];
                tempArr.push({ [key]: Object.values(attr)[1] });
              });

              let userAttr = Object.assign({}, ...tempArr);

              resolve(userAttr);
            }
          });
      }).catch(err => {
        reject(err);
      });
    });
  }

  updateUserAttributes(userId, userAttributes) {
    return new Promise((resolve, reject) => {
      // const attributes = this.setCognitoAttributeList(null, userAttributes);
      console.log(userAttributes);
      const data = {
        UserPoolId: this.getUserPool().getUserPoolId(),
        Username: userId,
        UserAttributes: userAttributes,
      };
      console.log("update user cognito payload", data);
      this.getCognitoIdentityServiceProvider().then(isp => {
        isp.adminUpdateUserAttributes(
          data,
          (err, data) => {
            if (err) {
              console.log("error update user attr", err);
              reject(err);
            } else {
              console.log("success update user attr", data);
              resolve(data);
            }
          }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }

  resetUserPassword(userId) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userId,
        UserPoolId: this.getUserPool().getUserPoolId(),
      };

      this.getCognitoIdentityServiceProvider().then(isp => {
        isp.adminResetUserPassword(
          userData,
          (err, data) => {
            if (err) reject(err);
            else resolve(data);
          }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }

  deleteUser(userId) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userId,
        UserPoolId: this.getUserPool().getUserPoolId(),
      };
      this.getCognitoIdentityServiceProvider().then(isp => {
        isp.adminDeleteUser(
          userData,
          (err, data) => {
            if (err) reject(err);
            else resolve(data);
          }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }
}
