<template>
  <div class="row border-bottom dashboard">
    <div class="col-12">
      <dashboard-summary class="first-box-container" />
    </div>

    <div class="col-md-4">
      <daily-feed />
    </div>
    <div class="col-md-4">
      <daily-conversation />
    </div>
    <div class="col-md-4">
      <div class="ibox">
        <div class="ibox-title">
          <h3>Test Confirmation Modal</h3>
        </div>
        <div class="ibox-content">
          <ConfirmationModal
            title="Are you sure?"
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Are you sure to proceed?"
            :actionAccept="confirmAccept"
            :actionReject="confirmReject"
            btnAcceptStyle="background-color:red;"
            ref="confirmModalTest"
          />
          <Button
            @click="confirmMe()"
            icon="pi pi-check"
            label="Confirm"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Button from "primevue/button";
import DashboardSummary from "@/components/summary/DashboardSummary";
import DailyFeed from "@/components/shared/DailyFeed";
import DailyConversation from "@/components/shared/DailyConversation";

export default {
  name: "Dashboard",
  components: {
    DashboardSummary,
    Button,
    DailyFeed,
    DailyConversation,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Home", route: "/" }],
    };
  },
  mounted() {
    this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
  },
  computed: {
    ...mapState({
      count: (state) => state.counter.count,
    }),
    // ...mapGetters(["counter/getCount"]),
  },
  methods: {
    ...mapActions({
      counterIncrement: "counter/INCREMENT",
    }),
    incrementCount() {
      this.counterIncrement();
    },
    confirmMe() {
      this.$refs.confirmModalTest.show();
    },
    confirmAccept() {
      console.log("confirmation accepted");
    },
    confirmReject() {
      console.log("confirmation rejected");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.first-box-container {
  background: white;
  margin: -20px -25px 20px;
  padding: 20px;
}
</style>
