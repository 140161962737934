// import { local } from "@/http/axios";
import http from "@/http/axios";

// const CONTENT_MANAGEMENT_BASEPATH = "/data";

const content = {
  namespaced: true,
  state: {
    contentfaq: null,
  },
  mutations: {
    setContentFaq(state, items) {
      state.contentfaq = items;
    },
  },
  actions: {
    SET_CONTENT_FAQ: ({ dispatch, commit }, items) => {
      console.log('SET_CONTENT_FAQ', dispatch, commit, items);
    },
    GET_CONTENT_FAQ: ({ dispatch, commit }) => {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const url = "/content/manage/faq";
      return new Promise((resolve, reject) => {
        http
          .get(url)
          .then(res => {
            const data = res.data;
            console.log("result get content faq", data);
            commit("setContentFaq", data);
            resolve(data);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(err => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(err);
          });
      });
    },
    SAVE_CONTENT_FAQ(_store, payload) {
      const url = "/content/manage";
      const data = {
        data: {
          content: payload.content,
          title: payload.title,
          category: payload.category,
          meta: 'faq',
          sortOrder: payload.sortOrder,
        }
      }
      return http.post(url, data);
    },
    UPDATE_CONTENT_FAQ(_store, payload) {
      const url = "/content/manage";
      const data = {
        data: {
          id: payload.id,
          content: payload.content,
          title: payload.title,
          category: payload.category,
          meta: "faq",
          sortOrder: payload.sortOrder,
        }
      }
      return http.put(url, data);
    },
    DELETE_CONTENT_FAQ(_store, payload) {
      const url = "/content/manage";
      const data = {
        data: {
          id: payload.id,
          meta: "faq"
        }
      }
      return http.delete(url, data);
    }
  },
  getters: {
    getContentFaq(state) {
      return state.contentfaq;
    },
  },
};

export default content;
