<template>
  <div class="app" :class="bodyClass">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      bodyClass: (state) => state.general.bodyClass,
    }),
  },
};
</script>
