<template>
  <div class="videos-detail-page">
    <page-heading>User Detail Page</page-heading>
    <b-tabs class="tabs-container">
      <b-tab ref="details" title="Details">
        <div class="panel-body">
          <form-detail
            v-if="userDetail"
            :id="id"
            :detail="userDetail"
            :createMember="createMember"
            :userAttribute="userCognitoAttribute"
          ></form-detail>
        </div>
      </b-tab>
      <b-tab ref="activtiy" title="Activity">
        <div class="panel-body">
          <activity-detail :id="id" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageHeading from "@/components/pageComponents/PageHeading";
import { BTabs, BTab } from "bootstrap-vue";
import FormDetail from "./partials/FormDetail";
import ActivityDetail from "./partials/ActivityDetail";

export default {
  data() {
    return {
      createMember: false,
      userDetail: null,
      id: null,
    };
  },
  created() {
    this.id = this.$route.params.id;
    const path = this.$route.path;
    console.log(this.$route);
    if (path === "/users/add") this.createMember = true;
  },
  mounted() {
    if (!this.id) return;
    this.$store.dispatch("users/GET_COUNTRY_LIST",);
    this.$store.dispatch("users/GET_USER_DETAIL", this.id).then((res) => {
      console.log("user", res);
      this.userDetail = res;
    });
    this.$store.dispatch("auth/getUserAttributes", this.id);
  },
  computed: {
    ...mapState({
      //userDetail: state => state.users.userDetail,
      loading: (state) => state.general.dataLoading,
      userCognitoAttribute: (state) => state.auth.selectedUserAttribute,
    }),
  },
  components: {
    PageHeading,
    BTabs,
    BTab,
    FormDetail,
    ActivityDetail,
  },
};
</script>
