<template>
  <div class="hello">
    <page-heading>Support page</page-heading>
    <p>This is support page</p>
    <p>Sample vuex counter: {{ count }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageHeading from "@/components/pageComponents/PageHeading";

export default {
  data() {
    return {
      breadcrumbs: [
        { title: "Home", route: "/" },
        { title: "Support", route: "/support" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
  },
  computed: {
    ...mapState({ count: (state) => state.counter.count }),
  },
  components: {
    PageHeading,
  },
};
</script>
