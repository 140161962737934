<template>
  <div class="detail-page">
    <page-heading>Venues Management</page-heading>
    <tab :tabs="tabs" @onClickTab="tabClicked">
      <div role="tabpanel" class="tab-pane" :class="setActiveTab('details')">
        <div class="panel-body" id="tab1" name="panel1">
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox">
                <div class="ibox-title">
                  <h5>Profile</h5>
                </div>
                <div class="ibox-content">
                  <form-detail></form-detail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane" :class="setActiveTab('activity')">
        <div class="panel-body" id="tab2" name="panel2">
          <activity-detail />
        </div>
      </div>
    </tab>
  </div>
</template>

<script>
import tab from "@/components/tab/tab";
import PageHeading from "@/components/pageComponents/PageHeading";
import FormDetail from "./partials/FormDetail";
import ActivityDetail from "./partials/ActivityDetail";

export default {
  data() {
    return {
      currentActiveIdx: 0,
    };
  },
  computed: {
    tabs: function() {
      let tabs = [
        {
          title: "Details",
          hash: "details",
        },
        {
          title: "Activity",
          hash: "activity",
        },
      ];

      return tabs;
    },
  },
  methods: {
    tabClicked: function(idx) {
      this.currentActiveIdx = idx;
    },
    setActiveTab: function(hash) {
      let activeIdx = this.tabs.findIndex(
        (t) => t.hash.replace(/\s/g, "") == hash
      );
      return activeIdx == this.currentActiveIdx ? "active" : "";
    },
  },
  components: {
    tab,
    PageHeading,
    FormDetail,
    ActivityDetail,
  },
};
</script>
