<template>
  <div>
    <h2 class="mb-5">
      {{ localVideo.title === "" ? "Video Details" : localVideo.title }}
    </h2>

    <small-loader class="d-block mx-auto" v-if="loading" />

    <b-card>
      <b-card-text>
        <validation-observer ref="detailsForm" #default="{ invalid }">
          <b-form @submit.prevent="submit">
            <b-row>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label-for="title"
                  label="Title *"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    vid="title"
                    rules="required"
                  >
                    <b-form-input id="title" v-model="localVideo.title" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Vendor *"
                  label-for="vendor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Vendor"
                    vid="vendor"
                    rules="required"
                  >
                    <b-form-input id="vendor" v-model="localVideo.vendor" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Synopsis"
                  label-for="synopsis"
                >
                  <b-form-textarea
                    id="synopsis"
                    v-model="localVideo.synopsis"
                    rows="5"
                    max-rows="100"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Tags (enter tags separated by space)"
                  label-for="tags"
                >
                  <b-form-tags
                    id="tags"
                    v-model="localVideo.tags"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    separator=" "
                    placeholder=""
                    remove-on-delete
                    no-add-on-enter
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Genre *"
                  label-for="genre"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Genre"
                    vid="genre"
                    rules="required"
                  >
                    <b-form-select
                      id="genre"
                      :options="genres"
                      v-model="localVideo.genre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Director"
                  label-for="director"
                >
                  <b-form-input id="director" v-model="localVideo.director" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Actors (enter names separated by semicolon)"
                  label-for="actors"
                >
                  <b-form-tags
                    id="actors"
                    v-model="localVideo.actors"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    separator=";"
                    placeholder=""
                    remove-on-delete
                    no-add-on-enter
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Categories (enter names separated by semicolon)"
                  label-for="categories"
                >
                  <b-form-tags
                    id="categories"
                    v-model="localVideo.categories"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    separator=";"
                    placeholder=""
                    remove-on-delete
                    no-add-on-enter
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Content Rating"
                  label-for="contentRating"
                >
                  <b-form-input
                    id="contentRating"
                    v-model="localVideo.contentRating"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Duration (minutes) *"
                  label-for="duration"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Duration"
                    vid="duration"
                    rules="required"
                  >
                    <b-form-input
                      id="duration"
                      v-model="localVideo.duration"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Ticket Price (GBP/£) *"
                  label-for="price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    vid="price"
                    rules="required"
                  >
                    <b-form-input
                      id="price"
                      v-model="localVideo.price"
                      type="number"
                      step="0.01"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" offset-md="2">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Venue"
                  label-for="venue"
                >
                  <b-form-select
                    id="venue"
                    :options="venueOptions"
                    v-model="localVideo.venue"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Video status *"
                  label-for="status"
                  v-slot="{ ariaDescribedby }"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    vid="status"
                    rules="required"
                  >
                    <div class="i-checks">
                      <label>
                        <input
                          v-model="localVideo.status"
                          type="radio"
                          value="released"
                          name="video_status"
                          :aria-describedby="ariaDescribedby"
                        />
                        <i></i> Released
                      </label>
                    </div>
                    <div class="i-checks">
                      <label>
                        <input
                          v-model="localVideo.status"
                          type="radio"
                          checked=""
                          value="comingsoon"
                          name="video_status"
                          :aria-describedby="ariaDescribedby"
                        />
                        <i></i> Coming Soon
                      </label>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" offset-md="6">
                <div class="ibox">
                  <div class="ibox-title">
                    Upcoming Venues (up to 2)
                    <div class="ibox-tools">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="primary"
                        class="mr-1 btn-add-new-venue btn-sm"
                        @click="addVenue"
                        :disabled="
                          localVideo.nextShowingVenues &&
                            localVideo.nextShowingVenues.length >= 2
                        "
                      >
                        Add new
                      </b-button>
                    </div>
                  </div>
                  <div class="ibox-content">
                    <b-row
                      class="venue-item"
                      v-for="(nextVenue, idx) in localVideo.nextShowingVenues"
                      :key="idx"
                    >
                      <b-col sm="1" class="num">{{ idx + 1 }}</b-col>
                      <b-col sm="10">
                        <b-form-group
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          label="Venue"
                          label-for="next-showing-venue"
                        >
                          <b-form-select
                            name="next-showing-venue"
                            :options="venueOptionsSimple"
                            v-model="nextVenue.venueId"
                          />
                        </b-form-group>
                        <b-form-group
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          label="Short info (e.g: availability dates)"
                          label-for="venueDesc"
                        >
                          <b-form-input
                            name="venueDesc"
                            v-model="nextVenue.description"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="1">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1 btn-danger mt-4"
                          @click="removeVenue(idx)"
                        >
                          X
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-5" v-if="mode === 'edit'">
              <b-col md="3">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Created Date"
                  label-for="createdDate"
                >
                  <b-form-input
                    id="createdDate"
                    v-model="localVideo.createdDate"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Updated Date"
                  label-for="updatedDate"
                >
                  <b-form-input
                    id="updatedDate"
                    v-model="localVideo.updatedDate"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Show Url"
                  label-for="showUrl"
                >
                  <b-form-input id="showUrl" v-model="video.showUrl" disabled />
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Meta Title"
                  label-for="metaTitle"
                >
                  <b-form-input id="metaTitle" v-model="localVideo.metaTitle" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Meta Description"
                  label-for="metaDescription"
                >
                  <b-form-textarea
                    id="metaDescription"
                    v-model="localVideo.metaDescription"
                    rows="5"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <!-- submit and cancel -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid"
                >
                  {{ localVideo.id !== null ? "Update" : "Create" }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.back()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SmallLoader from "@/components/SmallLoader";
import { cloneDeep } from "lodash";
import { GENRES } from "@/models/genres";

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    SmallLoader,
  },
  directives: {
    Ripple,
  },
  props: {
    video: null,
  },
  computed: {
    mode() {
      return this.video.id === null ? "new" : "edit";
    },
    genres: () => GENRES,
    venueOptions() {
      return this.venuesList.map((item) => {
        return {
          text: item.name,
          value: {
            id: item.id,
            name: item.name,
            city: item.city,
            country: item.country,
          },
        };
      });
    },
    venueOptionsSimple() {
      return this.venuesList.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
    ...mapState({
      venuesList: (state) => state.venues.listOfVenues,
      loading: (state) => state.general.dataLoading,
    }),
  },
  data() {
    return {
      localVideo: {},
    };
  },
  watch: {
    video: {
      handler(newVal) {
        this.localVideo = cloneDeep(newVal);
      },
      immediate: true,
    },
  },
  mounted() {
    //this.localVideo = cloneDeep(this.video);
  },
  methods: {
    addVenue() {
      const newVenue = {
        venueId: null,
        description: null,
      };

      if (!this.localVideo.nextShowingVenues) {
        Vue.set(this.localVideo, "nextShowingVenues", []);
      }

      this.localVideo.nextShowingVenues.push(newVenue);
    },
    removeVenue(idx) {
      this.localVideo.nextShowingVenues.splice(idx, 1);
    },
    submit: async function() {
      try {
        if (await this.$refs.detailsForm.validate()) {
          this.sendUpdateEvent(this.localVideo);
          if (!this.localVideo.id) {
            const result = await this.$store.dispatch(
              "videoStore/CREATE_VIDEO",
              this.localVideo
            );

            this.localVideo = result;

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Video was created successfully.",
              life: 3000,
            });

            this.$router.replace({
              name: "videoDetail",
              params: { id: result.id },
            });
          } else {
            const result = await this.$store.dispatch(
              "videoStore/UPDATE_VIDEO",
              this.localVideo
            );
            this.localVideo = result;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Video was updated successfully.",
              life: 3000,
            });
          }
        } else {
          this.$toast.add({
            severity: "warn",
            summary: "Warning",
            detail: "* required fields",
            life: 3000,
          });
        }
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
        console.log(err);
      }
    },
    sendUpdateEvent(result) {
      this.$emit("videoUpdated", result);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.num {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}
.btn-add-new-venue {
  margin-top: -8px;
}
</style>
