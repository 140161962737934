<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-sm-4">
      <h2><slot></slot></h2>
      <breadcrumb />
    </div>
    <div class="col-sm-8">
      &nbsp;
    </div>
  </div>
</template>

<script>
import breadcrumb from "./Breadcrumb";

export default {
  components: {
    breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.page-heading {
  margin: -20px -25px 20px;
}
</style>
