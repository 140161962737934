<template>
  <div class="videos-page">
    <page-heading>Venues Management</page-heading>
    <small-loader class="d-block mx-auto" v-if="loading" />

    <div v-else>
      <div class="row">
        <div class="col-12">
          <ListingSummary :summary="venuesSummary" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ListingFilter
            @clearFilter="clearFilter"
            @doSearch="doSearch"
            @resetSearch="resetSearch"
            :showSearchResult.sync="showSearchResult"
            :filterKeyword.sync="filterKeyword"
            :searchKeyword.sync="searchKeyword"
          >
            <button
              class="btn btn-lg btn-primary"
              @click="
                $router.push({
                  name: 'venueAdd',
                })
              "
            >
              Add venue
            </button>
          </ListingFilter>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table
            :dataArray="filteredVenueList"
            :columns="columns"
            :actionLink="actionLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListingSummary from "@/components/shared/ListingSummary";
import ListingFilter from "@/components/shared/ListingFilter";
import PageHeading from "@/components/pageComponents/PageHeading";
import DataTable from "@/components/datatable/datatable";
import SmallLoader from "@/components/SmallLoader";

export default {
  data() {
    return {
      columns: null,
      actionLink: "venueDetail",
      breadcrumbs: [
        { title: "Home", route: "/" },
        { title: "Venues", route: "/venues" },
      ],
      filterKeyword: "",
      searchKeyword: "",
      showSearchResult: false,
    };
  },
  created() {
    this.$store.dispatch("general/SET_BREADCRUMBS", this.breadcrumbs);
    this.$store.dispatch("venues/GET_VENUES");
    this.$store.dispatch("venues/GET_VENUES_SUMMARY");

    this.columns = [
      { field: "name", header: "Venue Name", sortable: true },
      { field: "email", header: "Email Address", sortable: true },
      { field: "country", header: "Country", sortable: true },
      { field: "updatedDateTime", header: "Latest Upload", sortable: true },
      { field: "", header: "Action" },
    ];
  },
  computed: {
    ...mapState({
      venuesList: (state) => state.venues.listOfVenues,
      loading: (state) => state.general.dataLoading,
      venuesSummary: (state) => state.venues.summaryList,
    }),
    filteredVenueList() {
      return this.venuesList.filter(
        (item) =>
          item.name.toLowerCase().includes(this.filterKeyword.toLowerCase()) ||
          item.email.toLowerCase().includes(this.filterKeyword.toLowerCase()) ||
          item.country.toLowerCase().includes(this.filterKeyword.toLowerCase())
      );
    }
  },
  methods: {
    clearFilter() {
      this.filterKeyword = "";
    },
    async doSearch() {
      if (this.searchKeyword != "") {
        await this.$store.dispatch("venues/SEARCH_VENUES", this.searchKeyword);
        this.showSearchResult = true;
      }
    },
    resetSearch() {
      this.searchKeyword = "";
      this.$store.dispatch("venues/RESET_SEARCH");
      this.showSearchResult = false;
    },
  },
  components: {
    PageHeading,
    DataTable,
    SmallLoader,
    ListingSummary,
    ListingFilter,
  },
};
</script>
