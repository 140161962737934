/* eslint-disable no-unused-labels */
import axios from "@/http/axios";
const orderServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
};

const venues = {
  namespaced: true,
  state: {
    listOfVenues: [],
    originalListOfVenues: [],
    venueData: {},
    summaryList: [],
    lastEvaluatedKey: null,
    startEvalueatedKey: null,
  },
  actions: {
    GET_VENUES({ commit, dispatch }, projectionExpression = null) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve) => {
        const url =
          projectionExpression === null
            ? "/venue/manage"
            : "/venue/manage?pe=" + projectionExpression;

        // axios({method: "GET", url, baseURL: "http://localhost:4001"})
        axios
          .get(url)
          .then((resp) => {
            const data = resp.data;
            commit("setVenuesState", data);
            resolve(data);
            // if (resp.data.startItem) commit("setStartEvaluatedKey", resp.data.startItem);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    GET_VENUE({ commit, dispatch }, id) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve) => {
        const url = "/venue/manage/" + id;
        // let json = venues.json;
        // axios({method: "GET", url, baseURL: "http://localhost:4001"})

        axios
          .get(url)
          .then((resp) => {
            // const data = resp.data.find(x => x.id == id);
            const data = resp.data;
            commit("setVenueData", data);
            resolve(data);

            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    SEARCH_VENUES({ dispatch, commit }, keyword) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      try {
        const url = "/venue/manage/search";
        const data = { keyword: keyword };
        axios
          .post(url, data)
          .then((resp) => {
            if (resp.status == 200) {
              commit("setVenuesList", resp.data);
            }
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      } catch (err) {
        console.log(err);
      }
    },
    RESET_SEARCH({ commit }) {
      commit("resetVenuesList");
    },
    GET_VENUES_SUMMARY({ commit }) {
      try {
        const url = "/venue/manage/summary";
        axios
          .get(url)
          .then((resp) => {
            if (resp.status == 200) {
              commit("setSummaryList", resp.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    UPDATE_VENUE({ commit }, venueData) {
      const data = {
        id: venueData.id,
        name: venueData.name,
        email: venueData.email,
        address: venueData.address,
        contact: venueData.contact,
        secondAddress: venueData.address2,
        city: venueData.city,
        postCode: venueData.postCode,
        state: venueData.state,
        country: venueData.country,
        description: venueData.description,
        mediaFiles: venueData.artworks,
        latitude: venueData.latitude,
        longitude: venueData.longitude,
        website: venueData.website,
        similarVenues: venueData.similarVenues,
        metaTitle: venueData.metaTitle,
        metaDescription: venueData.metaDescription
      };

      return new Promise((resolve) => {
        const url = "/venue/manage";
        axios
          .put(url, data)
          .then((resp) => {
            if (resp.status == 200) commit("setVenueData", data);

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    SAVE_VENUE({ dispatch }, venueData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const data = {
        name: venueData.name,
        email: venueData.email,
        address: venueData.address,
        contact: venueData.contact,
        secondAddress: venueData.address2,
        city: venueData.city,
        postCode: venueData.postCode,
        state: venueData.state,
        country: venueData.country,
        description: venueData.description,
        mediaFiles: venueData.artworks,
        latitude: venueData.latitude,
        longitude: venueData.longitude,
        website: venueData.website,
        similarVenues: venueData.similarVenues,
        metaTitle: venueData.metaTitle,
        metaDescription: venueData.metaDescription
      };

      return new Promise((resolve) => {
        const url = "/venue/manage";
        axios
          .post(url, data)
          .then((resp) => {
            resolve(resp);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    DELETE_VENUE(_store, id) {
      return new Promise((resolve) => {
        const url = "/venue/manage/" + id;

        axios
          .delete(url)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    REMOVE_ARTWORK(_store, imagePath) {
      const url = "/venue/delete-artwork";
      const data = {
        image: imagePath,
      };

      return new Promise((resolve) => {
        axios.put(url, data).then((resp) => {
          resolve(resp);
        });
      });
    },
    GET_PURCHASE_HISTORY(store, params) {
      const qs = `?from=${params.from}&to=${params.to}`;
      const url = "/order/venue/" + params.id + qs;
      return axios.get(url, { headers: orderServiceReqHeaders });
    },
  },
  mutations: {
    setVenuesState(state, payload) {
      state.listOfVenues = [
        ...state.listOfVenues
          .concat(payload)
          .reduce(
            (m, o) => m.set(o.id, Object.assign(m.get(o.id) || {}, o)),
            new Map()
          )
          .values(),
      ];
      state.originalListOfVenues = state.listOfVenues;
    },
    setVenuesList(state, payload) {
      state.listOfVenues = payload;
    },
    resetVenuesList(state) {
      state.listOfVenues = state.originalListOfVenues;
    },
    setVenueData(state, payload) {
      state.venueData = payload;
    },
    setNowShowingList(state, payload) {
      state.listNowShowingVenue = payload;
    },
    setSummaryList(state, payload) {
      state.summaryList = payload;
    },
    setLastEvaluatedKey(state, payload) {
      state.lastEvaluatedKey = payload;
    },
    setStartEvaluatedKey(state, payload) {
      state.startEvalueatedKey = payload;
    },
  },
  getters: {
    getVenues(state) {
      return state.listOfVenues;
    },
    getVenuesOptions() {
      // return state.listOfVenues.map(v => {
      //   text: v.name;
      //   value: v;
      // });
    },
    isVenueOkToDelete(state) {
      return state.venueData.isShowingNow;
    },
  },
};

export default venues;
