<template>
  <div :class="'modal-confirm' + (isActive ? ' active' : '')">
    <div class="modal-body">
      <h4 class="modal-title">{{ title }}</h4>
      <div class="modal-message">{{ message }}</div>
      <div class="modal-action-btn-container">
        <button
          type="button"
          class="modal-action-btn modal-action-btn-reject"
          :class="iconReject"
          v-on:click="doActionReject"
          :style="btnRejectStyle"
        >
          {{ btnRejectTitle }}
        </button>
        <button
          type="button"
          class="modal-action-btn modal-action-btn-accept"
          :class="iconAccept"
          v-on:click="doActionAccept"
          :style="btnAcceptStyle"
        >
          {{ btnAcceptTitle }}
        </button>
      </div>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script>
export default {
  name: "confirm",
  props: {
    title: {
      type: String,
      default: "Confirmation",
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    actionAccept: {
      type: Function,
      default: () => {},
    },
    actionReject: {
      type: Function,
      default: () => {},
    },
    btnAcceptTitle: {
      type: String,
      default: "Delete",
    },
    btnRejectTitle: {
      type: String,
      default: "Cancel",
    },
    btnAcceptStyle: {
      type: String,
      default: "",
    },
    btnRejectStyle: {
      type: String,
      default: "",
    },
    iconAccept: {
      type: String,
      default: "fa fa-trash-o",
    },
    iconReject: {
      type: String,
      default: "fa fa-times",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    show() {
      this.isActive = true;
    },
    hide() {
      this.isActive = false;
    },
    doActionAccept() {
      this.actionAccept();
      this.isActive = false;
    },
    doActionReject() {
      this.actionReject();
      this.isActive = false;
    },
  },
};
</script>
<style scoped lang="scss">
h4 {
  font-size: 38px;
}

.modal-confirm {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  &.active {
    display: block;
  }
}
.modal-overlay {
  position: fixed;
  background: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.modal-body {
  background-color: #fff;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 0 0 silver;
}
.modal-message {
  max-width: 350px;
}
.modal-action-btn-container {
  margin-top: 66px;
}
.modal-action-btn {
  border: 0;
  padding: 10px 20px;
  border-radius: 20px;
  min-width: 150px;
  &.modal-action-btn-accept {
    background-color: green;
    color: white;
    font-weight: bold;
  }
  &.modal-action-btn-reject {
    background-color: silver;
    color: rgb(105, 105, 105);
    margin-right: 20px;
  }
}
</style>
