import AWS from "aws-sdk";
import {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import AuthTokens from "@/models/AuthTokens";
import CognitoApiProxy from './cognito-api';

export default class S3ApiProxy {
  constructor({
    cognitoAWSRegion = process.env.VUE_APP_COGNITO_AWS_REGION,
    s3AWSRegion = process.env.VUE_APP_AWS_REGION,
    bucketName = process.env.VUE_APP_CONTENTS_BUCKET_NAME,
    cognitoUserPoolId = process.env.VUE_APP_COGNITO_USER_POOL_ID,
  } = {}) {
    if (!bucketName) throw new Error("Bucket name is required");
    if (!cognitoAWSRegion) throw new Error("AWS Cognito Region is required");
    if (!s3AWSRegion) throw new Error("AWS Bucket Region is required");
    if (!cognitoUserPoolId) throw new Error("Cognito User Pool ID is required");

    this.s3AWSRegion = s3AWSRegion;
    this.cognitoUserPoolId = cognitoUserPoolId;
    this.cognitoAWSRegion = cognitoAWSRegion;

    const rawTokens = JSON.parse(localStorage.getItem("tokens"));
    let tokens = rawTokens && Object.keys(rawTokens).length > 0  ? new AuthTokens(rawTokens) : null;

    if (tokens) {
      if (tokens.idTokenExpiry - 120 <= Date.now() / 1000) {
        new CognitoApiProxy().checkAndRenewTokenForExpiration()
          .then(newTokens => {
            if (newTokens) {
              tokens = newTokens;
              console.log("tokens renewed");
              localStorage.setItem("tokens", JSON.stringify(newTokens));
              
              const jwtToken = new AuthTokens(tokens).idToken;

              const logins = {};
              logins[
                `cognito-idp.${this.cognitoAWSRegion}.amazonaws.com/${this.cognitoUserPoolId}`
              ] = jwtToken;

              AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
                Logins: logins,
              });

              AWS.config.region = s3AWSRegion;
              AWS.config.credentials.refresh();

              this.s3 = new S3Client({
                region: s3AWSRegion,
                credentials: AWS.config.credentials,
              });
          
              this.bucketName = bucketName;
            }
          }).catch(err => {
            console.error(err);
            localStorage.clear();
            window.location.href = "/signin";
          });
      } else {

        const jwtToken = new AuthTokens(tokens).idToken;

        const logins = {};
        logins[
          `cognito-idp.${this.cognitoAWSRegion}.amazonaws.com/${this.cognitoUserPoolId}`
        ] = jwtToken;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
          Logins: logins,
        });

        AWS.config.region = s3AWSRegion;
        AWS.config.credentials.refresh();

        this.s3 = new S3Client({
          region: s3AWSRegion,
          credentials: AWS.config.credentials,
        });
    
        this.bucketName = bucketName;
      }
    }
  }

  /**
   * List folders that exist in the bucket
   * @returns {string[]>
   */
  async listFolders() {
    try {
      let folders = [];

      const data = await this.s3.send(
        new ListObjectsCommand({ Delimiter: "/", Bucket: this.bucketName })
      );

      if (data.CommonPrefixes !== undefined) {
        folders = data.CommonPrefixes.map((commonPrefix) => {
          var prefix = commonPrefix.Prefix;
          return decodeURIComponent(prefix.replace("/", ""));
        });
      }

      return folders;
    } catch (err) {
      throw new Error(
        "There was an error listing your folders: " + err.message
      );
    }
  }

  /**
   * Check if folder is exist or not in the bucket
   * @param {string} folderName
   * @returns {bool}
   */
  async isFolderExist(folderName) {
    try {
      const folderFilesKey = encodeURIComponent(folderName) + "/";
      const data = await this.s3.send(
        new ListObjectsCommand({
          Prefix: folderFilesKey,
          Bucket: this.bucketName,
        })
      );

      if (data.Contents !== undefined) {
        return (
          data.Contents.find(
            (content) =>
              decodeURIComponent(content.Key.replace("/", "")) === "folderName"
          ) !== null
        );
      } else {
        return false;
      }
    } catch (err) {
      throw new Error(
        "There was an error listing your folders: " + err.message
      );
    }
  }

  /**
   * Create an folder in the bucket
   * @param {string} folderName
   */
  async createFolder(folderName) {
    folderName = folderName.trim();
    if (!folderName) {
      throw new Error(
        "Folder name must contain at least one non-space character."
      );
    }
    if (folderName.indexOf("/") !== -1) {
      throw new Error("Folder name cannot contain slashes.");
    }
    var folderKey = encodeURIComponent(folderName);
    try {
      const key = folderKey + "/";
      const params = { Bucket: this.bucketName, Key: key, ACL: "public-read" };
      await this.s3.send(new PutObjectCommand(params));
    } catch (err) {
      throw new Error(
        "There was an error creating your folder: " + err.message
      );
    }
  }

  /**
   * Upload file to a folder
   * @param {File} file
   * @param {string} folderName
   */
  async uploadFile(file, fileName, folderName) {
    try {
      const folderFilesKey = folderName + "/";

      const fileKey = folderFilesKey + encodeURIComponent(fileName);
      const uploadParams = {
        Bucket: this.bucketName,
        Key: fileKey,
        Body: file,
      };

      await this.s3.send(new PutObjectCommand(uploadParams));

      return `https://${this.bucketName}.s3.${this.s3AWSRegion}.amazonaws.com/${fileKey}`;
    } catch (err) {
      if (!file) {
        throw new Error("No file is being provided");
      } else {
        throw new Error(
          "There was an error uploading your file: ",
          err.message
        );
      }
    }
  }

  /**
   * Upload video to an folder
   * @param {File} file
   * @param {string} folderName
   */
  async uploadVideoFile(file, fileName, folderName, progressCallback) {
    try {
      const folderFilesKey = folderName + "/";

      const fileKey = folderFilesKey + encodeURIComponent(fileName);
      const params = {
        Bucket: this.bucketName,
        Key: fileKey, 
        Body: file,
      };

      const uploader = new Upload({
        client: this.s3,
        params,
        partSize: 10 * 1024 * 1024,
        queueSize: 7, 
      });

      if (progressCallback) {
        uploader.on("httpUploadProgress", (progress) => {
          progressCallback(progress.loaded / progress.total * 100);
        });
      }
  
      await uploader.done();
      return `https://${this.bucketName}.s3.${this.s3AWSRegion}.amazonaws.com/${fileKey}`;
    } catch (err) {
      if (!file) {
        throw new Error("No file is being provided");
      } else {
        throw new Error("There was an error uploading your file", err.message);
      }
    }
  }

  /**
   * Delete a file from the bucket
   * @param {string} fileKey
   */
  async deleteFile(fileKey) {
    try {
      const params = { Key: fileKey, Bucket: this.bucketName };
      await this.s3.send(new DeleteObjectCommand(params));
    } catch (err) {
      return alert("There was an error deleting your file: ", err.message);
    }
  }

  /**
   * Delete an folder from the bucket
   * @param {string} folderName
   */
  async deleteFolder(folderName) {
    const folderKey = encodeURIComponent(folderName) + "/";
    try {
      const params = { Bucket: this.this.bucketName, Prefix: folderKey };

      const data = await this.s3.send(new ListObjectsCommand(params));

      const objects = data.Contents.map(function(object) {
        return { Key: object.Key };
      });

      try {
        const params = {
          Bucket: this.bucketName,
          Delete: { Objects: objects },
          Quiet: true,
        };

        await this.s3.send(new DeleteObjectCommand(params));
      } catch (err) {
        return alert("There was an error deleting your folder: ", err.message);
      }
    } catch (err) {
      return alert("There was an error deleting your folder1: ", err.message);
    }
  }
}
