<template>
  <div />
</template>
<script>
export default {
  name: "SignOut",
  mounted() {
    this.$store.dispatch("auth/signOut");
    this.$router.push({ name: "dashboard" });
    window.location.reload();
  },
};
</script>