<template>
  <div class="gray-bg vh-100">
    <toast />
    <router-view />
    <toast position="top-right" />
  </div>
</template>

<script>
import Toast from "primevue/toast";

export default {
  components: {
    Toast,
  },
};
</script>
