<template :class="bodyClass">
  <div id="wrapper" :class="bodyClass">
    <main-navbar />
    <Toast />
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <div class="row border-bottom">
        <top-nav />
      </div>
      <div class="wrapper wrapper-content">
        <div class="row">
          <div class="col-lg-12">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import MainNavbar from "@/components/navbar/MainNavbar";
import TopNav from "@/components/navbar/TopNav";
import Toast from "primevue/toast";

export default {
  created() {
    // console.log(this.$route.meta.cssClass);
    // this.setBodyClass(this.$route.meta.cssClass);
  },
  computed: {
    ...mapState({
      bodyClass: (state) => state.general.bodyClass,
    }),
    ...mapActions({
      setBodyClass: "general/SET_BODYCLASS",
    }),
  },
  methods: {
    //
  },
  components: {
    MainNavbar,
    TopNav,
    Toast,
  },
};
</script>
