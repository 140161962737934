const counter = {
  namespaced: true,
  state: {
    count: 10,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
  },
  actions: {
    INCREMENT({ commit }) {
      commit("increment");
    },
  },
  getters: {
    getCount(state) {
      return state.count;
    },
  },
};

export default counter;
