<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="ibox-tools">
        <span
          class="label float-right"
          :class="`label-${data.class}`"
          v-if="data.subtitle"
        >
          {{ data.subtitle }}
        </span>
      </div>
      <h5>{{ data.title }}</h5>
    </div>
    <div class="ibox-content">
      <h2 class="no-margins">{{ data.description }}</h2>
      <div class="stat-percent font-bold" :class="`text-${data.class}`">
        <i class="fa" :class="data.faIcon"></i>
      </div>
      <small class="description-title d-block"
        >{{ data.descriptionTitle }} &nbsp;</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: "Recent Activity",
          subtitle: "daily",
          description: "5 minutes ago",
          class: "success", // info, primary
          faIcon: "fa-eye",
          descriptionTitle: "Total visitors",
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.description-title {
  min-height: 20px;
}
</style>