<template>
  <div class="summary-container d-flex" v-if="summary.length">
    <div
      class="summary-item ibox mr-4"
      v-for="item in summary"
      :key="item.title"
    >
      <div class="ibox-content">
        <div class="title">{{ item.title }}</div>
        <div class="number">{{ item.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.summary-item {
  width: 270px;
}
.title {
  font-size: 18px;
  margin-bottom: 10px;
}
.number {
  font-size: 16px;
}
</style>
