import axios from "@/http/axios";
const orderServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
};
const VIDEO_MANAGEMENT_BASEPATH = "/video/manage";

const videos = {
  namespaced: true,
  state: {
    videos: [],
    lastEvaluatedKey: null,
    videosStatistic: {},
  },
  mutations: {
    addVideo(state, item) {
      state.videos.unshift(item);
    },
    updateVideo(state, item) {
      const idx = state.videos.findIndex((v) => v.id === item.id);
      if (idx > -1) {
        Object.assign(state.videos[idx], item);
      } else {
        state.videos.unshift(item);
      }
    },
    setVideos(state, { videos, lastEvaluatedKey }) {
      state.videos = videos;
      state.lastEvaluatedKey = lastEvaluatedKey;
    },
    setVideosStatistic(state, payload) {
      state.videosStatistic = payload;
    }
  },
  actions: {
    CREATE_VIDEO: async ({ commit, dispatch }, item) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        Object.keys(item).forEach(
          (key) => item[key] === undefined && delete item[key]
        );
        const response = await axios.post(VIDEO_MANAGEMENT_BASEPATH, item);
        commit("addVideo", Object.assign(item, { id: response.data.id }));
        return response.data;
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    UPDATE_VIDEO: async ({ commit, dispatch }, item) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        Object.keys(item).forEach(
          (key) => item[key] === undefined && delete item[key]
        );
        const response = await axios.put(VIDEO_MANAGEMENT_BASEPATH, item);
        commit("updateVideo", response.data);
        return response.data;
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    GET_VIDEOS: async ({ commit, dispatch }, projectionExpression = null) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      const url =
        projectionExpression === null
          ? VIDEO_MANAGEMENT_BASEPATH
          : `${VIDEO_MANAGEMENT_BASEPATH}?pe=${projectionExpression}`;

      try {
        const response = await axios.get(url);
        commit("setVideos", response.data);
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    GET_VIDEO_DETAIL: async ({ commit, dispatch }, id) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        const response = await axios.get(`${VIDEO_MANAGEMENT_BASEPATH}/${id}`);

        let respData = response.data;
        delete respData.venue["mediaFiles"];

        console.log(respData);
        commit("updateVideo", respData);
        return respData;
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    UPDATE_VIDEO_FILES: async ({ commit, state }, videoFiles) => {
      // dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        Object.keys(videoFiles).forEach(
          (key) => videoFiles[key] === undefined && delete videoFiles[key]
        );
        await axios.put(`${VIDEO_MANAGEMENT_BASEPATH}/contents`, {
          videoId: videoFiles.videoId,
          videoFiles,
        });
        const video = state.videos.find((x) => x.id === videoFiles.videoId);
        if (video) {
          Object.assign(video.videoFiles, videoFiles);
          commit("updateVideo", video);
        }
      } finally {
        // dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    UPDATE_VIDEO_ARTWORKS: async ({ commit, dispatch, state }, artworks) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        Object.keys(artworks).forEach(
          (key) => artworks[key] === undefined && delete artworks[key]
        );
        await axios.put(`${VIDEO_MANAGEMENT_BASEPATH}/contents`, {
          videoId: artworks.videoId,
          artworks,
        });
        const video = state.videos.find((x) => x.id === artworks.videoId);
        if (video) {
          Object.assign(video.artworks, artworks);
          commit("updateVideo", video);
        }
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    GET_PURCHASE_HISTORY(store, params) {
      const qs = `?from=${params.from}&to=${params.to}`;
      const url = "/order/product/" + params.id + qs;
      return axios.get(url, { headers: orderServiceReqHeaders });
    },
    GET_VIDEOS_STATISTIC: async ({ commit, dispatch }) => {
      dispatch("general/SET_DATALOADING", true, { root: true });

      try {
        const response = await axios.get(VIDEO_MANAGEMENT_BASEPATH + "/stat");
        console.log(response.data);
        commit("setVideosStatistic", response.data);
      } finally {
        dispatch("general/SET_DATALOADING", false, { root: true });
      } 
    }
  },
  getters: {
    getVideos(state) {
      return { videos: state.videos, lastEvaluatedKey: state.lastEvaluatedKey };
    },
  },
};

export default videos;
