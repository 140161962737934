<template>
  <nav
    class="navbar navbar-static-top bg-gray"
    role="navigation"
    style="margin-bottom: 0"
  >
    <div class="navbar-header">
      <a
        class="navbar-minimalize minimalize-styl-2 btn btn-primary d-none d-md-block"
        href="#"
        @click="toggleMiniNavbar"
      >
        <i class="fa fa-bars"></i>
      </a>
      <a
        class="navbar-minimalize minimalize-styl-2 btn btn-primary d-block d-md-none"
        href="#"
        @click="toggleMobileNavbar"
      >
        <i class="fa fa-bars"></i>
      </a>
      <form
        role="search"
        class="navbar-form-custom"
        action="search_results.html"
      >
        <div class="form-group">
          <input
            type="text"
            placeholder="Search for something..."
            class="form-control"
            name="top-search"
            id="top-search"
          />
        </div>
      </form>
    </div>
    <ul class="nav navbar-top-links navbar-right">
      <li class="pr-2">
        <router-link to="/signout">
          <i class="fa fa-sign-out"></i> Sign out
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      miniNavbar: (state) => state.general.miniNavbar,
      mobileNavbar: (state) => state.general.mobileNavbar,
    }),
  },
  methods: {
    toggleMiniNavbar() {
      this.$store.dispatch("general/SET_MININAVBAR", !this.miniNavbar);
    },
    toggleMobileNavbar() {
      this.$store.dispatch("general/SET_MOBILENAVBAR", !this.mobileNavbar);
    },
  },
};
</script>
