<template>
  <div class="videos-page">
    <page-heading>Featured Shows</page-heading>
    <h4>Pick up to 4 shows to be featured on the home page</h4>
    <small-loader class="d-block mx-auto" v-if="loading" />
    <div v-else class="form-group row">
      <div class="col-12">
        <MultiSelect
          :options="videos"
          :label="'title'"
          :placeHolder="'Select Shows'"
          :maxSelection="4"
          :selectedOptions="featuredVideos"
          @maxSelectionReached="showNotifMaxSelectionReached"
          @dataSelected="mapDataFromSelector"
        ></MultiSelect>
        <!-- <select
          class="form-control"
          multiple=""
          v-model="videosSelected"
          @change="checkMax(videosSelected)"
          size="20"
        >
          <option
            v-for="video in videos"
            :value="video.id"
            :key="video.id"
            :selected="video.selected"
          >
            {{ video.title }}
          </option>
        </select> -->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-sm-offset-2">
        <button class="btn btn-primary btn-lg mr-2" @click="saveOrUpdate">
          <i class="fa fa-arrow-down"></i>&nbsp;Save changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { onUnmounted, onMounted } from "@vue/composition-api";
import PageHeading from "@/components/pageComponents/PageHeading";
import SmallLoader from "@/components/SmallLoader";
// import store from "@/store";
// import videoStore from "@/pages/videos/store";
// import useVideoListing from "@/pages/videos/useVideoListing";
import MultiSelect from "@/components/multiselect/multiselect";

export default {
  data() {
    return {
      //      videos: [],
      videosSelected: [],
    };
  },
  // setup() {
  //   console.log(["THIS", this]);
  //   const breadcrumbs = [
  //     { title: "Home", route: "/" },
  //     { title: "Featured", route: "/featured" },
  //   ];

  //   store.dispatch("general/SET_BREADCRUMBS", breadcrumbs);

  //   if (!videoStore.isRegistered) {
  //     store.registerModule("videoStore", videoStore);
  //     videoStore.isRegistered = true;
  //   }

  //   store.dispatch("videoStore/GET_VIDEOS");

  //   // ...........................................................................
  //   onMounted(async () => {
  //     await store.dispatch("videoStore/GET_VIDEOS");
  //     //refetchData();
  //     fetchVideos(this, (data) => {
  //       console.log(["resNOW",data]);
  //       this.videos = data
  //     });
  //   });

  //   // ...........................................................................
  //   onUnmounted(() => {

  //   });

  //   const {
  //     fetchVideos,
  //     tableColumns,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refVideosTable,
  //     statusFilter,
  //     refetchData,
  //     statisticsItems,
  //     refVideos,
  //   } = useVideoListing();

  //   return {
  //     fetchVideos,
  //     tableColumns,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refVideosTable,
  //     statusFilter,
  //     refetchData,
  //     statisticsItems,
  //     refVideos,
  //   };
  // },
  created() {
    this.$store
      .dispatch("featured/GET_FEATURED")
      .finally(() => this.$store.dispatch("videos/GET_VIDEOS"));
  },
  computed: {
    ...mapState({
      loading: state => state.general.dataLoading,
      videos: state => state.videos.videos,
      featuredVideos: state => state.featured.featuredVideos,
    }),
    //...mapGetters(["videos/GET_VIDEOS", "featured/GET_FEATURED"]),
  },
  watch: {
    videos(newVal) {
      console.log("videos", newVal);
    },
    featuredVideos(newVal) {
      console.log("featuredVideos", newVal);
    },
  },
  methods: {
    checkMax(val) {
      console.log(val);
      if (val.length > 4) {
        console.log(" you can only select fours");
        val.splice(4);
      }
    },
    populateVideosList() {
      this.$store.dispatch("videoStore/GET_VIDEOS", "id,name").then(res => {
        console.log(["VIDEOS", res]);
        this.videos = res
          .filter(item => item.id !== this.id)
          .map(video => {
            video.selected = this.videosSelected.includes(video.id);
            return video;
          });
      });
    },
    showNotifMaxSelectionReached(hasReachMaxSelection) {
      if (hasReachMaxSelection)
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "You can only select 4 shows",
          life: 3000,
        });
    },
    mapDataFromSelector(data) {
      console.log("From parent - selected data:", data);
      this.videosSelected = data.map(d => {
        return {
          id: d.id,
          meta: "details",
        };
      });
      console.log("mapped data", this.videosSelected);
    },
    saveOrUpdate() {
      this.$store
        .dispatch("featured/SET_FEATURED", this.videosSelected)
        .then(res => {
          if (res)
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Data has been saved successfully",
              life: 3000,
            });
        })
        .catch(err => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          });
        });
    },
  },
  components: {
    PageHeading,
    SmallLoader,
    MultiSelect,
  },
};
</script>
