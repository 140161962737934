<template>
  <div id="app">
    <canvas id="bar-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        };
      },
    },
  },
  data() {
    // TODO: chartData become props
    return {
      type: "bar",
    };
  },
  mounted() {
    this.createChart(this.chartData);
  },
  methods: {
    createChart(chartData) {
      const ctx = document.getElementById("bar-chart");
      new Chart(ctx, {
        type: this.type,
        data: chartData,
        options: this.options,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  max-height: 260px;
}
</style>
