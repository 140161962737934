<template>
  <div>
    <h2 class="mb-5">Video Files</h2>
    <div>
      <small-loader class="d-block mx-auto" v-if="loading" />
      <div v-else>
        <b-card class="mb-3" hidden>
          <h3>MP4 - 2 Dimensions</h3>
          <b-card-text>
            <b-row>
              <b-col md="12">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Low Definition"
                >
                  <file-upload
                    ref="mp4_2d_ld"
                    name="mp4_2d_ld"
                    :customUpload="true"
                    :auto="false"
                    :uploadProgress="uploadProgress['mp4_2d_ld']"
                    :isUploadProgress="isUploadProgress['mp4_2d_ld']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_2d_ld"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Medium Definition"
                >
                  <file-upload
                    ref="mp4_2d_md"
                    name="mp4_2d_md"
                    :uploadProgress="uploadProgress['mp4_2d_md']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['mp4_2d_md']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_2d_md"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="High Definition"
                >
                  <file-upload
                    ref="mp4_2d_hd"
                    name="mp4_2d_hd"
                    :uploadProgress="uploadProgress['mp4_2d_hd']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['mp4_2d_hd']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_2d_hd"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card class="mb-3">
          <h3>MP4 - 3 Dimensions</h3>
          <b-card-text>
            <b-row>
              <b-col md="12">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Low Definition"
                >
                  <file-upload
                    ref="mp4_3d_ld"
                    name="mp4_3d_ld"
                    :uploadProgress="uploadProgress['mp4_3d_ld']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['mp4_3d_ld']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_3d_ld"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Medium Definition"
                >
                  <file-upload
                    ref="mp4_3d_md"
                    name="mp4_3d_md"
                    :uploadProgress="uploadProgress['mp4_3d_md']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['mp4_3d_md']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_3d_md"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="High Definition"
                >
                  <file-upload
                    ref="mp4_3d_hd"
                    name="mp4_3d_hd"
                    :uploadProgress="uploadProgress['mp4_3d_hd']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['mp4_3d_hd']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.mp4_3d_hd"
                    >
                      {{ video.title }} video has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card class="mb-3">
          <h3>Trailer</h3>
          <b-card-text>
            <b-row>
              <b-col md="12">
                <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
                  <file-upload
                    ref="trailer_2d_md"
                    name="trailer_2d_md"
                    :customUpload="true"
                    :uploadProgress="uploadProgress['trailer_2d_md']"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['trailer_2d_md']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                    accept="video/mp4,.mp4"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.trailer_2d_md"
                    >
                      {{ video.title }} video trailer has been uploaded.
                    </p>
                    <span v-else>No video.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card class="mb-3">
          <h3>Subtitles</h3>
          <b-card-text>
            <b-row>
              <b-col md="12">
                <b-form-group v-ripple="'rgba(255, 255, 255, 0.35)'">
                  <file-upload
                    ref="subtitles"
                    name="subtitles"
                    :customUpload="true"
                    :auto="true"
                    :isUploadProgress="isUploadProgress['subtitles']"
                    @uploader="uploadVideoFile"
                    accept=".srt"
                    :multiple="true"
                  ></file-upload>
                </b-form-group>
                <div
                  class="image-container"
                  v-if="video.videoFiles && video.videoFiles.subtitles"
                >
                  <p class="font-weight-bold" v-if="video.videoFiles.subtitles">
                    <span
                      v-for="(subtitle, idx) of video.videoFiles.subtitles"
                      :key="idx"
                    >
                      {{ subtitle.substr(subtitle.lastIndexOf("/") + 1) }}
                      <br />
                    </span>
                  </p>
                  <span v-else>No subtitles.</span>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card class="mb-3">
          <h3>Ambisonic Sounds</h3>
          <b-card-text>
            <b-row>
              <b-col md="12">
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="Android"
                >
                  <file-upload
                    ref="android_ambisonic"
                    name="android_ambisonic"
                    :uploadProgress="uploadProgress['android_ambisonic']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['android_ambisonic']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles && video.videoFiles.android_ambisonic">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.android_ambisonic"
                    >
                      Android ambisonic sound has been uploaded.
                    </p>
                    <span v-else>No file.</span>
                  </div>
                </b-form-group>
                <b-form-group
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  label="iOS"
                >
                  <file-upload
                    ref="ios_ambisonic"
                    name="ios_ambisonic"
                    :uploadProgress="uploadProgress['ios_ambisonic']"
                    :customUpload="true"
                    :auto="false"
                    :isUploadProgress="isUploadProgress['ios_ambisonic']"
                    @uploader="uploadVideoFile"
                    :multiple="false"
                  ></file-upload>
                  <div class="image-container" v-if="video.videoFiles && video.videoFiles.ios_ambisonic">
                    <p
                      class="font-weight-bold"
                      v-if="video.videoFiles.ios_ambisonic"
                    >
                      iOS ambisonic sound has been uploaded.
                    </p>
                    <span v-else>No file.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol, BFormGroup, BCardText } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SmallLoader from "@/components/SmallLoader";
import FileUpload from "@core/components/file-upload/FileUpload.vue";
import S3ApiProxy from "@/services/s3-api";

const s3 = new S3ApiProxy();

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    FileUpload,
    SmallLoader,
  },
  directives: {
    Ripple,
  },
  props: {
    video: {},
  },
  computed: {
    ...mapState({
      loading: (state) => state.general.dataLoading,
    }),
  },
  data() {
    return {
      videoFiles: this.video.videoFiles || {},
      isUploadProgress: {},
      uploadProgress: {},
    };
  },
  mounted() {},
  methods: {
    async uploadVideoFile(name, files) {
      try {
        let folderName = "";
        const _isUploadProgress = { ...this.isUploadProgress };
        _isUploadProgress[name] = true;
        this.isUploadProgress = _isUploadProgress;

        if (name.indexOf("trailer") > -1) {
          folderName = `trailers/${encodeURIComponent(this.video.id)}`;
          const fileName = `${name}${files[0].name.slice(
            files[0].name.lastIndexOf(".")
          )}`;

          this.videoFiles[name] = await s3.uploadVideoFile(
            files[0],
            fileName,
            folderName
          );
        } else if (name.indexOf("subtitles") > -1) {
          folderName = `subtitles/${encodeURIComponent(this.video.id)}`;
          this.videoFiles[name] = [];
          const promises = [];

          files.forEach((file) => {
            promises.push(s3.uploadFile(file, file.name, folderName));
          });

          const result = await Promise.all(promises);
          this.videoFiles[name] = result;
        } else {
          folderName = `videos/${encodeURIComponent(this.video.id)}`;
                    
          const fileName = name.indexOf("android_ambisonic") > -1 || name.indexOf("ios_ambisonic") > -1 
            ? `${name}`
            : `${name}${files[0].name.slice(
              files[0].name.lastIndexOf(".")
            )}`;
          
          console.log("*** uploading video...");

          this.videoFiles[name] = await s3.uploadVideoFile(
            files[0],
            fileName,
            folderName,
            (progress) => {
              console.log(`*** progress ${name}`, progress);
              const _uploadProgress = { ...this.uploadProgress };
              _uploadProgress[name] = Math.ceil(progress);
              this.uploadProgress = _uploadProgress;
            }
          );
        }
        
        if(name.indexOf("android_ambisonic") > -1)
          this.videoFiles.android_ambisonic_size = files[0].size;
        else if(name.indexOf("ios_ambisonic") > -1)
          this.videoFiles.ios_ambisonic_size = files[0].size;
        
        this.videoFiles.videoId = this.video.id;
        await this.$store.dispatch(
          "videoStore/UPDATE_VIDEO_FILES",
          this.videoFiles
        );
        // // clear files after upload is success
        // this.$refs[name].files = "";
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Video files was uploaded and updated successfully.",
          life: 3000,
        });
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
        console.log(err);
      } finally {
        const __isUploadProgress = { ...this.isUploadProgress };
        __isUploadProgress[name] = false;
        this.isUploadProgress = __isUploadProgress;
      }
    },
  },
};
</script>
